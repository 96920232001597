import classes from './ListView.scss';
import {Link} from 'react-router-dom';

const ListView = (props) => {
	const item = props.item;
	return (
		<div className="row mt-4">
			<div className="col-md-4 list_image_wrap">
				<img src={item.image}/>
			</div>
			<div className="col">
				<Link to={"/blogs/"+item.slug} className="fw-bold">{item.title}</Link>
			</div>
		</div>
	);
};
export default ListView;