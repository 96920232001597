import { Link } from 'react-router-dom';
import classes from './SectionScore.scss';
import Plx from 'react-plx';

const parallaxData = [
	// {
	//     start: 'self',
	//     startOffset: 0,
	//     duration: 500,
	//     properties: [
	//         {
	//         startValue: 0,
	//         endValue: 1,
	//         property: 'opacity',
	//         },
	//         {
	//             startValue: -300,
	//             endValue: 0,
	//             property: 'translateX',
	//         },
	//     ],
	// },
];

const SectionScore = (ScoreContent) => {
	const scores = Object.entries(ScoreContent.scores);

	// const link = ScoreContent.sectionLink;
	return (
		<Plx className='st_parallax' parallaxData={parallaxData} >
			<div className="row col-md-12 col-sm-12 gutterless">
				{
					scores.map((item, index) => {
						let is_last_item = (index == (scores.length - 1));
						return (
							<div className={(is_last_item ? ' position-relative' : '') + " col-auto text-center score-box px-4"}>
								<p className="counter">
									{item[1]}
									<small>{item[0].replace('_', ' ').toUpperCase()}</small>
								</p>
								{/* {is_last_item && (
								<Link className="section_link" to="/vision-statement">
									VISION STATEMENT
									<span>
										<i className="fas fa-chevron-right"></i>
									</span>
								</Link>
							)} */}
							</div>
						);
					})
				}
			</div>
		</Plx>
	);
};

export default SectionScore;