import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import classes from './SectionBoxItem.scss';
import { Modal } from 'react-bootstrap';
import Plx from 'react-plx';

const SectionBoxItem = (props) => {
	const parallaxData = props.parallaxData;
	const [show, setShow] = useState(false);

	return (
		<div className="col-12 col-md-6 col-xl-4 mb-4 mt-md-5 section-box">
			<Plx className='st_parallax h-100' parallaxData={parallaxData} >
				<div className="box-wrapper h-100">
					<p className="box-prefix">{props.prefix}</p>
					<h1 className="box-title mt-3">{props.title}</h1>
					<p className="box-text mt-2">
						{props.description}
					</p>

					{props.children ??
						''
					}
					{/* <Link to="/" className={"mt-4 " + props.button_class} onClick={() => setShow(true)}>{props.button_text}</Link> */}

				</div>
			</Plx>
		</div>
	);

};

export default SectionBoxItem;