import PageHeader from "../../common/PageHeader/PageHeader";
import ProjectFilter from "./ProjectFilter/ProjectFilter";
import ProjectGroup from "./ProjectGroup/ProjectGroup";
import classes from "./Project.scss";
import { Helmet } from "react-helmet";

const Product = (props) => {
    const page_title = "Our projects";
    const page_summary = "YI-Lab’s work is focused on technology development, disaster risk reduction, climate change, open data, entrepreneurship, data driven advocacy, youth engagement, and volunteerism. <br/> Check out some of our initiatives here. ";
    return (
        <>
            <Helmet>
				<title>YI-LAB | PROJECTS </title>
				<meta name="title" content="YI LAB's PROJECTS"/>
				<meta name="description" content="YI-Lab’s work is focused on technology development, disaster risk reduction, climate change, open data, entrepreneurship, data driven advocacy, youth engagement, and volunteerism. <br/> Check out some of our initiatives here."/>
				<meta name="keyword" content="youth, innovation, lab, projects, initiatives"/>
			</Helmet>
            <PageHeader title={page_title} summary={page_summary}></PageHeader>
            <ProjectGroup></ProjectGroup>
        </>
    );
};
export default Product;