import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader/PageHeader";
import Filter from "./Filter/Filter";
import PublicationGroup from "./PubicationGroup/PublicationGroup";
import classes from "./Publication.scss";

const Publication = (props) => {
	const page_title = "Publications";
	const page_summary = "Find all the publications and research reports produced by YI-Lab team";
	return (
		<>
			<Helmet>
				<title>YI-LAB | PUBLICATIONS </title>
				<meta name="title" content="PUBLICATIONS"/>
				<meta name="description" content="publications and research reports produced by YI-Lab team"/>
				<meta name="keyword" content="youth, innovation, lab, publication, research"/>
			</Helmet>
			
			<PageHeader title={page_title} summary={page_summary}></PageHeader>
			<PublicationGroup></PublicationGroup>
		</>
	);
};

export default Publication;