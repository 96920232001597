import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import LinkWithArrow from "../../common/LinkWithArrow/LinkWithArrow";
import "./footer.scss";
import NewsletterForm from "./NewsletterForm/NewsletterForm";
import YilabContainer from "../../common/YiLabContainer/YiLabContainer";

function Footer(props) {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const url = process.env.REACT_APP_API_BASE_URL + "/vacancy";

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result.results);
        },
        (error) => {
          setError(error);
        }
      );
  }, []);

  return (
    <section className="footer">
      <YilabContainer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-12 col-lg-6">
              <div className="get-in-touch">
                <p>
                  Didn’t find what you are looking for? Found our work
                  interesting? Want to know more and collaborate?{" "}
                </p>
                <LinkWithArrow
                  link_to="/contact"
                  link_text="GET IN TOUCH"
                ></LinkWithArrow>
                <a></a>
              </div>
              <div className="get-in-touch">
                <p>Come innovate with us!</p>
                <LinkWithArrow link_to="/vacancy" link_text="JOIN OUR TEAM">
                  {" "}
                </LinkWithArrow>
              </div>
              <div className="footer-navigation">
                <ul className="fotter-menu">
                  <li className="menu-item">
                    <Link to="/about">About</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/projects">Projects</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li className="menu-item position-relative">
                    <Link to="/vacancy">Careers</Link>
                    {items.length > 0 && (
                      <span className="career-opening">{items.length}</span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4 mt-5 mt-md-0">
              <div className="newsletter">
                <p>Keep up with us. Subscribe now to stay updated! </p>
              </div>
              <NewsletterForm />
              <div className="address">
                <p>
                  Bhakta Marga, Baluwatar
                  <br /> Kathmandu Metropolitan city-4
                  <br /> Kathmandu 44600, Nepal
                </p>
              </div>
              <div class="mt-4 social-media-icon">
                <div class="social-media">
                  <a href="https://www.facebook.com/YILabNepal" target="_blank">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://twitter.com/YILabNepal" target="_blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/youth-innovation-lab/"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </YilabContainer>
    </section>
  );
}

export default Footer;
