import React, { useState, useEffect } from 'react';
import HtmlParser from 'react-html-parser';
import classes from './ImageGroup.scss';
import LinkWithArrow from '../../../common/LinkWithArrow/LinkWithArrow';
import Loading from '../../../common/Loading/Loading';
import { Link } from 'react-router-dom';

const ImageGroup = (props) => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [items, setItems] = useState([]);
	const url = process.env.REACT_APP_API_BASE_URL + '/publication?limit=5&ordering=-id';

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItems(result.results);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, []);

	if (isLoading) {
		return <Loading />;
	}
	const item = props.item;

	return (
		items.length > 0 ?
			<div className="row row-eq-height">
				<div className="image-text col-12 mt-3 mt-md-0">
					<LinkWithArrow link_to="/publications" link_text="ALL PUBLICATIONS"></LinkWithArrow>
				</div>

				{/* <div className="col-md-5" style={{ backgroundImage: `url(${items[0].front_image})`, marginBottom: '1.5rem' }}> */}

				<div className="col-12 col-md-6 col-xl-4">
					<div className="pub_image_wrapper mb-4 mb-md-0 position-relative">
						<Link to={"/publications/" + items[0].slug}>
							<img src={items[0].front_image} className="w-100" />
						</Link>
						<div className="position-absolute publication-landing-tag">Latest</div>
					</div>
				</div>
				<div className="col-12 col-md-6 col-xl-4">
					<div className="row g-4">
						{items.map((item, index) => {
							if (index > 0) {
								return (
									<div className="col-md-6">
										<div className="pub_image_wrapper">
											<Link to={"/publications/" + item.slug}>
												<img src={item.front_image} className="w-100" />
											</Link>
										</div>
									</div>
								);
							}
						}
						)}
					</div>
				</div>

			</div > : ''
	);
};

export default ImageGroup;