import HtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import classes from './VacancyItem.scss'

const VacancyItem = (props) => {
    const item = props.item;
    return (
        <div className="col-md-12 col-lg-12 col-sm-12">
            <div className="vacancylisting-wrapper">
                <div className="vacancy-text p-4">
                    
                    <Link to={"/vacancy/" + item.slug} className="fw-bold"><h4>{item.title}</h4></Link>

                    {item.short_description?
                        <small className='jd_short'>
                            {item.short_description}
                        </small>
                    :''
                    }

                    {
                        item.formatted_apply_before?
                            <small className="mt-3 d-block">
                                Apply Before : <i>{item.formatted_apply_before}</i>
                                {/* {HtmlParser(item.short_description)} */}
                            </small>
                            :''
                    }
                </div>
            </div>
        </div>
    );
}
export default VacancyItem;