import { useState, useEffect } from "react";
import PageHeader from "../../common/PageHeader/PageHeader";
import SectionText from "../home/SectionText/SectionText";
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import ProjectFeature from "./ProjectFeature/ProjectFeature";
import ProjectImpactChart from "./ProjectImpactChart/ProjectImactChart";
import PublicationItem from "../publication/PubicationGroup/PublicationItem";
import ProjectTimeline from "./ProjectTimeline/ProjectTimeline";
import YiLabContainer from "../../common/YiLabContainer/YiLabContainer";
import { useParams } from "react-router";
import Loading from "../../common/Loading/Loading";
import ProjectItem from "../project/ProjectGroup/ProjectItem";
import ProjectDonor from "../ProjectDetail/ProjectDonor/ProjectDonor";
import BlogBlockD2 from "../blog/BlogBlockD2/BlogBlockD2";
import RecommendedReadings from "../../common/RecommendedReadings/RecommendedReadings";
import { Helmet } from "react-helmet";

const ProjectDetail = (props) => {

	const [error, setError] = useState(null);
	const [item, setItem] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const params = useParams();
	const slug = params.project;
	const url = process.env.REACT_APP_API_BASE_URL + '/project/' + slug + '?expand=donors';

	useEffect(() => {
		fetch(url)
			.then((res) => res.json())
			.then(
				(result) => {
					setItem(result);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}
		, [slug]);

	if (isLoading) {
		return <Loading />;
	}


	const page_title = item.title ?? '';
	const page_summary = item.quote ?? '';

	const section_about_us = {
		"prefix": "INDRODUCTION",
		"title": "About",
		"body": item.about_project ?? ""
	};

	const section_project_impact = {
		"prefix": "IMPACTS",
		"title": "Project’s Impact in Different Field",
		"body": item.extra_info.Impact ? item.extra_info.Impact.description : ""
	};

	const section_project_status = {
		"prefix": "STATUS",
		"title": "Phases of the Project",
		"body": item.extra_info.Phases ? item.extra_info.Phases.description : ""
	};
	return (
		<>
			<Helmet>
				<title>YI-LAB | {item.title} </title>
				<meta name="title" content={item.title}/>
				<meta name="description" content={item.title}/>
				<meta name="keyword" content="youth, innovation, lab, projects, initiatives"/>
			</Helmet>

			{/* Page Header  */}
			<PageHeader title={page_title} summary={page_summary}>
			</PageHeader>

			{/* About section  */}
			<SectionText contents={section_about_us}></SectionText>

			{/* Project Breif Section  | Alternative Section */}
			<ProjectFeature item={item}></ProjectFeature>


			{/* Project Impact Section  */}
			{(item.extra_info.Impact) ?
				<SectionText contents={section_project_impact} child_full_width="true">
					{item.extra_info.Impact.json_data ?
						<ProjectImpactChart chartData={item.extra_info.Impact.json_data}></ProjectImpactChart>
						: ''
					}
				</SectionText>
				: ''}

			{/* Project Status Section  */}
			{(item.extra_info.Phases) ?
				<SectionText contents={section_project_status}>
					{item.extra_info.Phases.json_data ?
						<ProjectTimeline timelineData={item.extra_info.Phases.json_data}></ProjectTimeline>
						: ""
					}
				</SectionText>
				: ''}
			<ProjectDonor item={item}></ProjectDonor>
			{/* Recommended Reading Section  */}
			<YiLabContainer>
				{(item.related_readings.length > 0) ?
					<SectionTextTitleContent title="Related Readings" >
						<RecommendedReadings items={item.related_readings} />
					</SectionTextTitleContent>
					: ''
				}
			</YiLabContainer>
		</>
	);
};

export default ProjectDetail;