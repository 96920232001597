import './background.css';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { height } from 'dom-helpers';

function Background(props) {
    const location = useLocation();
    const [height, setHeight] = useState(1500);
    const [svgHeight, setSvgHeight] = useState("300vw");

    useEffect(() => {
    //   runs on location, i.e. route, change
      
      
      if( height<1000 ){
          setSvgHeight('180vw');
      }else if(height < 2000){
          setSvgHeight('235vw');
      }else{
          setSvgHeight('245vw');
      }
    }, [location])


    

    return (
        <div className="background">
            <svg className="background-svg" viewBox="11803.59 -15311.923 2326.336 4431.551" style={{height:svgHeight}}>
                <radialGradient id="background-svg" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                    <stop offset="0" stopColor="#205182" stopOpacity="1"></stop>
                    <stop offset="0.4644" stopColor="#143b5e" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#1b233d" stopOpacity="0"></stop>
                </radialGradient>
                <path id="background-svg"
                    d="M 11992 -14643.62109375 C 11992 -14643.62109375 12098 -14481.798828125 12310.6669921875 -14617.822265625 C 12328 -14657.69140625 12410.6669921875 -14692.869140625 12472 -14689.744140625 C 12533.3330078125 -14686.6171875 12557.333984375 -14672.544921875 12598.6669921875 -14617.822265625 C 12640 -14563.099609375 12694 -14397.369140625 12890.6669921875 -14525.576171875 C 12938.6669921875 -14631.89453125 13000 -14842.966796875 13096 -14855.474609375 C 13192 -14867.98046875 13231.9990234375 -14872.671875 13297.3330078125 -14814.822265625 C 13362.6669921875 -14756.974609375 13456 -14578.734375 13456 -14578.734375 C 13456 -14578.734375 13733.3330078125 -14369.228515625 13884 -14578.734375 C 14034.6669921875 -14788.244140625 14050 -15867.62109375 14105.3330078125 -14943.029296875 C 14160.666015625 -14018.4375 14105.3330078125 -10880.3720703125 14105.3330078125 -10880.3720703125 L 11803.58984375 -10880.3720703125 L 11992 -14643.62109375 Z">
                </path>
            </svg>
            <div className="background_gradient"></div>
            {props.children}
        </div>

    );
}

export default Background;