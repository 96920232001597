import { useState, useEffect } from 'react';
import classes from './FeaturedBlog.scss';
import LinkWithArrow from '../../../common/LinkWithArrow/LinkWithArrow';
import HtmlParser from 'react-html-parser';
import Loading from '../../../common/Loading/Loading';

const FeaturedBlog = (props) => {
	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/article?limit=1';

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItems(result.results);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, []);


	if (isLoading) {
		return <Loading />;
	}

	return (
		items.length > 0 ?

			items.map((item) =>
				<div className="container-fluid yilab-container">
					<div className="featured_blog_wrapper row page_margin_left">
						<div className="col-md-4 position-relative">
							<img src={item.image} className="w-100" />
							<div className="position-absolute publication-tag">Latest</div>
						</div>
						<div className="col-md-8 blog_content">
							{/* <div className="blog_content_wrap d-flex">
								<div className="col-sm-6 py-2">LATEST</div>
								<div className="col-sm-6 blog_date py-2">{item.formatted_published_date}</div>
							</div> */}
							<div className="row blog_content_wrap">
								<div className="col-12 mt-4">
									<h1 className="h2">{item.title}</h1>
								</div>

								<div className="author my-4 col-12 ">
									<p className="me-1">
										By
										<author> {item.author} /</author>
									</p>
									<div className="blog_date"><p>Posted On <span> {item.formatted_published_date}</span></p></div>
								</div>


								<p>
									{HtmlParser(item.short_description)}
								</p>

								<LinkWithArrow link_to={"/blogs/" + item.slug} link_text="READ MORE"></LinkWithArrow>
							</div>
						</div>
					</div>
				</div>
			)
			: ''
	);
};

export default FeaturedBlog;