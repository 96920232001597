import { useEffect, useState } from 'react';
import classes from './SectionScoreOSM.scss'

const SectionScoreOSM = (props) => {

	const [items, setItems] = useState([]);

	const url = process.env.REACT_APP_API_BASE_URL + '/osm-info';

	useEffect(() => {
		fetch(url)
			.then(response => response.json())
			.then(result => {
				setItems(result.results);
			})
	}, [])


	return (
		<div className="row section-score-osm-wrapper">

			{items.map(item => (
				<div className="col-md-6 counter-box">
					<p className="counter">
						{parseInt(item.value).toLocaleString()}
					</p>
					<p className="counter_label">
						<img className="me-3" src={item.icon} />
						<span>{item.key}</span>
					</p>
				</div>
			))
			}



		</div>
	);
};

export default SectionScoreOSM;