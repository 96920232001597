import HtmlParser from 'react-html-parser';
import ListView from '../../../common/ListView/ListView';
import BlogBlockD2 from '../../blog/BlogBlockD2/BlogBlockD2';
import AboutAuthor from '../AboutAuthor/AboutAuthor';
import classes from './BlogContentBlock.scss';
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";


const BlogContentBlock = (props) => {
	const item = props.item;
	const images = [
		{
			url: item.image,
			title: item.title
		}
	];
	return (
		<>
			{/* Blog Featured Image  */}
			<div className="blog_image_wrap">
				{/* <Lightbox images={images} onClose={null} /> */}
				<img src={item.image} />
				<p className="blog-detail-photo-credit mt-2">Photo Credit: {item.photo_credit}</p>
			</div>

			{/* Blog content and sidebar  */}
			<div className="row mt-5 gx-0 gx-md-5 justify-content-center">
				<div className="col-12 col-md-8 col-lg-5 blog_content_rt">
					{HtmlParser(item.description)}
					{/* About Author Section  */}
					<AboutAuthor item={item}></AboutAuthor>
				</div>
				<div className="col-lg-1 d-none d-lg-block"></div>
				<div className="col-md-3 blog_sidebar_right p-md-0 pt-3 pt-md-3 d-none d-lg-block">
					<h4>RECOMMENDED READING</h4>
					{(item.recommended_blogs.length > 0) ?
						item.recommended_blogs.map((ritem) =>
							<ListView item={ritem} key={ritem.id}></ListView>
						)
						: ''
					}
				</div>
			</div>

			{/* Blog Recommended Reading Section  */}
			<div className="row mt-5">
				<h2 className="mb-1 mb-md-5">Recommended Reading</h2>
				{(item.recommended_blogs.length > 0) ?
					item.recommended_blogs.map((ritem) =>
						<BlogBlockD2 item={ritem} key={ritem.id}></BlogBlockD2>
					)
					: ''
				}
			</div>
		</>
	);
};

export default BlogContentBlock;