import LinkWithArrow from "../../common/LinkWithArrow/LinkWithArrow";
import PageHeader from "../../common/PageHeader/PageHeader";
import SectionList from "../home/SectionList/SectionList";
import SectionText from "../home/SectionText/SectionText";
import OSMModule from "./OSMModule/OSMModule";
import SectionScoreOSM from "./SectionScoreOSM/SectionScoreOSM";
import { useState } from "react";
import DigitalVolunteerForm from "../home/SectionBox/ModalForms/DigitalVolunteerForm";
import { Link } from "react-router-dom";
import './OSMPage.scss'
import { Helmet } from "react-helmet";

const OSMPage = (props) => {
	const [showDigitalVolunteerForm, setShowDigitalVolunteerForm] = useState(false);

	const page_summary = "One of our priorities is to mobilize Nepali youth and train them to initiate and lead initiatives for positive change.  YI-Lab has been carrying out a series of events, discussions, and trainings to support this mission, Digital Mapping being one of them.  We engage digital volunteers in remotely mapping disaster-prone areas in OpenStreetMap. OpenStreetMap (OSM) is the free wiki world map. It is open data that is free and frequently used in humanitarian sectors Maps produced in OSM is for the community, by the community and to the community. <br> Join the “Learning OpenStreetMap” training to become a certified Digital Volunteer and support us in our mission to bridge to public science divide with the use of cutting-edge technological tools.  ";

	const osm_module_section = {
		"prefix": "Learning OpenStreetMap"
	};
	const become_saviour_section = {
		"prefix": "OSM Volunteers ",
		"title": "How can you contribute to OSM mapping?",
		"body": "Contribute to OSM mapping via OSM Adda. OSM Adda is an online platform for OSM enthusiasts to hangout virtually. You can join the Adda to connect with mapping enthusiasts who are working on our projects in HOT Tasking Manager. You can share your mapping experience, problems, collaboratively solve issues and discuss new ideas while mapping together."
	};

	const digital_volunteers_section = {
		"prefix": "OUR CONTRIBUTION",
		"title": "OpenStreetMap",
		"body": "Given the reality that exposure datasets especially in the disaster-prone countries like Nepal is limited, Youth Innovation Lab has been engaging volunteers to bridge this gap by mapping the most vulnerable and remote areas of Nepal. This has added to the digitization of the mapped elements."
	};

	const section_whats_happening = {
		"className": "",
		"title": "What's <br> Happening?",
		"body": "View our <br> upcoming events."
	};

	const digitalVolnteerFormVisibilityHandler = (value) => {
		setShowDigitalVolunteerForm(value);
	};

	return (
		<>
			<Helmet>
				<title>YI-LAB | DIGITAL VOLUNTEERS </title>
				<meta name="title" content="DIGITAL VOLUNTEERS"/>
				<meta name="description" content="Contribute to OSM mapping via OSM Adda. OSM Adda is an online platform for OSM enthusiasts to hangout virtually. You can join the Adda to connect with mapping enthusiasts who are working on our projects in HOT Tasking Manager. You can share your mapping experience, problems, collaboratively solve issues and discuss new ideas while mapping together."/>
				<meta name="keyword" content="youth, innovation, lab, OSM, mapping, OSM Adda, disaster, digital volunteers, openstreetmap"/>
			</Helmet>

			<section className="osm-page">
				{/* Page Header Section  */}
				<PageHeader title="Digital Volunteers" summary={page_summary}></PageHeader>

				{/* OSM Module Section  */}
				<SectionText contents={osm_module_section}>
					<OSMModule></OSMModule>
				</SectionText>

				{/* Become saviour section  */}
				<SectionText contents={become_saviour_section}>
					<div className="link-with-arrow mb-3">
						<Link to="#" className={'normal-effect'} onClick={() => setShowDigitalVolunteerForm(true)}>
							{'REGISTER NOW'}
							<svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 20 13">
								<path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward" d="M20.629,11.5a.869.869,0,0,0-.007,1.246l4.224,4.123H8.771a.88.88,0,1,0,0,1.76H24.839l-4.224,4.123A.875.875,0,0,0,20.622,24a.916.916,0,0,0,1.271-.007l5.724-5.619h0a.987.987,0,0,0,.188-.278.822.822,0,0,0,.069-.339.871.871,0,0,0-.257-.616l-5.724-5.619A.9.9,0,0,0,20.629,11.5Z" transform="translate(-7.875 -11.251)" fill="#fff" />
							</svg>
						</Link>
					</div>
					<DigitalVolunteerForm show={showDigitalVolunteerForm} onVisibilityChange={digitalVolnteerFormVisibilityHandler}></DigitalVolunteerForm>
				</SectionText>

				{/* Digital Volunteers  */}
				<div className="digital-volunteers-wrapper">
					<SectionText contents={digital_volunteers_section} className="digital_volunteers">
						<SectionScoreOSM></SectionScoreOSM>
					</SectionText>
				</div>

				{/* Whats happening section  */}
				<SectionList contents={section_whats_happening}></SectionList>

				{/* Digital Volunteer Section  */}
				<SectionText contents={digital_volunteers_section}>
					<a href="https://hdyc.neis-one.org/" class="btn btn-info" target="_blank">Find a User</a>
				</SectionText>

			</section>
		</>
	);
};

export default OSMPage;