import classes from "./ProjectTimeline.scss";
import { Timeline} from 'rsuite';
const ProjectTimeline = (props) => {
	const timelineData = props.timelineData;
	const dot =  <img src="/images/timeline_dot.svg"/>;
	return (
		<div className="section_margin_top">
			<Timeline endless={true}>
				{timelineData.map((data)=>{
					let [key,value] = Object.entries(data)[0];
					return (
						<Timeline.Item className="timeline_wrap pt-2" dot={dot}>
							<div className="pl-4">
								<h5>{key}</h5>
								<p className="mt-4 py-4">
									{value}
								</p>
							</div>
						</Timeline.Item>
					)
				})}

			</Timeline>
		</div>
	);
};

export default ProjectTimeline;