import classes from './PublicationCarousel.scss';
import Carousel from 'react-bootstrap/Carousel'
import YiLabContainer from '../../../common/YiLabContainer/YiLabContainer';
import { Link } from "react-router-dom";
import HtmlParser from 'react-html-parser';

const PublicationCarousel = (props) => {
    const items = props.items;

    return (
        <YiLabContainer>
            <Carousel>
                {items.map((item, index) =>
                    (index <= 2)? 
                    <Carousel.Item interval={4000}>
                        <div className="row">
                            <div className="col-lg-2 hidden-class"></div>
                            <div className="col-10 col-md-6 col-lg-3 position-relative">
                                <Link to={"/publications/"+item.slug} >
                                    <img
                                        className="d-block w-100"
                                        src={item.front_image}
                                        alt="First slide"
                                    />
                                </Link>
                                <div className="position-absolute publication-carousel-tag">Latest</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <p className="h5">{item.formatted_published_date}</p>
                                <Link to={'/publications/'+item.slug}><p className="h3">{item.name} </p></Link>
                                <p className="slider-description">{HtmlParser(item.description)}</p>
                            </div>
                            <div className="col-lg-2 hidden-class"></div>
                        </div>

                        {/* <Carousel.Caption>
                            <h3>{item.formatted_published_date}</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption> */}
                    </Carousel.Item>
                    :''
                )}

            </Carousel>
        </YiLabContainer>

    );
}
export default PublicationCarousel;