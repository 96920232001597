import { Link, useLocation } from 'react-router-dom';
import classes from './PublicationContent.scss';
import PublicationCollapse from '../PublicationCollapse/PublicationCollapse';
import Overlay from 'react-bootstrap/Overlay';
import Button from '@restart/ui/esm/Button';
import { Tooltip } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import HtmlParser from 'react-html-parser';

const PublicationContent = (props) => {
	const item = props.item;

	const current_url = window.location.href;
	
	const getFileExtension = (path) => {
		let path_parts = path.split(".");
		let total_parts = path_parts.length;
	
	  if( total_parts > 1 ){
		return path_parts[total_parts - 1];
	  }else{
		return '';
	  }
	}

	return (
		<div className="publication-detail-wrapper">
			<div className="row">
				<div className="col-lg-3 col-12">
					<div className="publication-image-content">
						<p>{item.formatted_published_date}</p>
						<p>{item.publisher}</p>

					</div>
					<div className="publication-image">
						<img src={item.front_image} className="" />
					</div>
					<div className="pubication-share-social mt-4">
						<p className="h5">Share On</p>
						<div className="icon d-flex">
							<a href={"https://www.facebook.com/sharer/sharer.php?u="+current_url} target="_blank"><i class="fab fa-facebook-square  me-4"></i></a>
							<a href={"https://twitter.com/intent/tweet?url="+current_url} target="_blank"><i class="fab fa-twitter-square me-4"></i></a>
							<a href={"https://www.linkedin.com/shareArticle?mini=true&url="+current_url} target="_blank"><i class="fab fa-linkedin me-4"></i></a>
						</div>
					</div>
					{/* <div className="publication-stat mt-4 ">
						<p className="h5">Stats</p>
						<div class="detail-panel">
							<div class="image-wrap">
								<div class="d-flex flex-wrap">
									<div class="stats me-5 d-grid">
										<span class="number">358</span>
										<span>Views</span>
									</div>
									<div class="icon me-5">
										<img src="/images/stats.png" />
									</div>
									<div class="stats d-grid">
										<span class="number">48</span>
										<span>Downloads</span>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</div>
				<div className="col-lg-6 col-12 px-3 px-lg-5 mt-4 mt-lg-0">
					<div className="my-3 invisible d-none d-lg-flex">
						<p>blank</p>
					</div>
					<p className="h4 pt-2">{item.name}</p>
					<p className="mt-4">{HtmlParser(item.description)}</p>

					<>
						<div className="publication-specification mt-5">
							<p className="h5">Citations</p>
							{HtmlParser(item.extra_rich_text)}
						</div>
					</>

				</div>
				<div className="col-lg-3 col-12">
					<div className="my-3 invisible d-none d-lg-flex">
						<p>blank</p>
					</div>
					<div className="download-wrapper mb-4 p-4">
						<p className="h5 mb-3">Read/Download Document</p>
						<a href={item.file} target="_blank" class="btn btn-info download-button me-3" download={item.slug+'.'+getFileExtension(item.file)}>Download</a>
						<a href={item.file} target="_blank" class="btn btn-info read-button">Read Online</a>
					</div>
					<div className="publication-detail-info p-4 mt-4 mt-lg-0 mb-4">
						<p>
							Pages:
							<a>{item.pages}</a>
						</p>
						<p>
							language:
							<a>{item.language}</a>
						</p>
						<p>
							Authors:
							<a>{item.authors.join(', ')}</a>
						</p>
						<p>
							Publisher Place:
							<a>{item.publisher_place}</a>
						</p>
						<p className="link">
							External Link:
							<a href={item.external_link} target="_blank">{item.external_link}</a>
						</p>
					</div>
					<div className="publication-content p-4 mt-4 mt-lg-0">
						{(item.tags.length > 0) ?
							<div className="publication-keywords">
								<h6>keywords</h6>
								<ul>
									{item.tags.map((tag) =>
										<li>
											<a href="#">{tag.name}</a>
										</li>
									)}
								</ul>
							</div>
							: ''}

					</div>

				</div>
			</div>
		</div>
	);
};

export default PublicationContent;