import HtmlParser from "react-html-parser";
import LinkWithArrow from "../../../common/LinkWithArrow/LinkWithArrow";
import classes from "./BlogBlock.scss";

const BlogBlock = (props) => {
	const item = props.item;
	return (
		<div className="container-fluid yilab-container">
			<div className="row my-4 m-md-0">
				<div className="col-md-6 mb-4 m-md-0">
					<h1 className="h2">{item.title}</h1>
					<div className="d-flex my-4 blog-author-date">
						<author className="col-md-12 py-2 d-flex">
							<div className="me-1">By {item.author} /</div>
							<div className="blog_date">Posted On {item.formatted_published_date}</div>
						</author>

					</div>
					<p className="mt-4">
						{HtmlParser(item.short_description)}
					</p>
					<LinkWithArrow link_to={"/blogs/" + item.slug} link_text="READ MORE"></LinkWithArrow>
				</div>
				<div className="col-md-6 bloglisting-img d-none d-md-inline">
					<img src={item.image} className="w-100" />
				</div>
			</div>
		</div>
	);
};

export default BlogBlock;