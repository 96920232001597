import LinkWithArrow from "../../../common/LinkWithArrow/LinkWithArrow";
import classes from './AboutAuthor.scss';
const AboutAuthor = (props) => {
	const item = props.item;
	return (
		<>
			<div className="about_author py-3">
				<p className="">About {item.author}</p>
				<p>{item.about_author}</p>
				{item.author_slug ?
					<LinkWithArrow link_to={"/team/" + item.author_slug} link_text="ABOUT AUTHOR"></LinkWithArrow>
					: ''}
			</div>
		</>
	);

};

export default AboutAuthor;