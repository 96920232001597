import { useState, useEffect } from 'react';
import Loading from '../../../common/Loading/Loading';
import YiLabContainer from "../../../common/YiLabContainer/YiLabContainer";
import Filter from '../Filter/Filter';
import PublicationCarousel from './PublicationCarousel';
import PublicationItem from "./PublicationItem";

const PublicationGroup = () => {
	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	// for pagination
	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);

	// for filter 
	const [keyword, setKeyword] = useState('');
	const [year, setYear] = useState('');
	const [type, setType] = useState('');

	// for url generate 
	const [param, setParam] = useState('/publication?offset=0&limit=' + process.env.REACT_APP_ITEMS_PER_PAGE);


	const filterChangeHandler = (keyword, year, type) => {
		setKeyword(keyword);
		setYear(year);
		setType(type);
		setItems([]);
		setPage(1);
	}

	useEffect(() => {
		let new_params = getParams();
		setParam(new_params);
	}, [keyword, year, type]);

	// this function returns required parameters based on the page and filter details 
	const getParams = () => {
		let limit = process.env.REACT_APP_ITEMS_PER_PAGE;
		let offset = (page * limit) - limit;
		let params = `/publication?limit=${limit}&offset=${offset}`;

		if (keyword) {
			params += "&name=" + keyword;
		}
		if (year) {
			params += "&year=" + year;
		}
		if (type) {
			params += "&type=" + type;
		}
		// let new_url = process.env.REACT_APP_API_BASE_URL+params;
		return params;
	}

	const handleLoadMore = () => {
		let new_page = page + 1;
		setPage(new_page);
	}

	useEffect(() => {
		let new_param = getParams();
		// fetching url 
		let url = process.env.REACT_APP_API_BASE_URL + new_param;
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					if (result.next != null) {
						setHasNext(true);
					} else {
						setHasNext(false);
					}
					let new_items = [...items, ...result.results];
					setItems(new_items);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, [param, page]);

	return (
		<>
			<PublicationCarousel items={items}></PublicationCarousel>
			<Filter search={filterChangeHandler} items={items} filterActive={() => setIsLoading(true)}></Filter>

			{
				isLoading ?
					<Loading />
					:
					items.length > 0 ?
						<>
							<YiLabContainer>
								<div className="row g-5">
									{items.map((item) =>
										<PublicationItem item={item} key={"pubg" + item.id}></PublicationItem>
									)}
								</div>

								<div className="loadMore text-center mt-5">
									{(hasNext) ?
										<button className="btn btn-sm btn-secondary" onClick={handleLoadMore}>Load More</button>
										: ''
									}
								</div>

							</YiLabContainer>
						</>
						: ''
			}
		</>
	);
};

export default PublicationGroup;