import { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import NavItem from "@restart/ui/esm/NavItem";

const ValidationSchema = Yup.object().shape({
	name: Yup.string()
		.required(),
	email: Yup.string()
		.required('Email is required')
		.email(),
	contact_number: Yup.string()
		.required('Contact Number is required'),
});

const EventJoinForm = (props) => {

	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");


	const url = process.env.REACT_APP_API_BASE_URL + '/join-event-form/';
	let global_response = "";
	const event_id = props.event_id;

	const handleClose = () => {
		setSuccessMessage('');
		setErrorMessage('');
		props.handleClose();
	}

	const formSubmitHandler = (values, resetForm, setSubmitting) => {
		// POST request using fetch inside useEffect React hook
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: values
		};
		fetch(url, requestOptions)
			.then(
				(response) => {
					global_response = response;
					return response.json();
				}
			)
			.then(
				(result) => {
					if (global_response.ok) {
						setSuccessMessage("Registration Successful.");
					} else {
						for (const r in result) {
							setErrorMessage(result[r][0]);
							break;
						}
					}
					resetForm();
					setSubmitting(false);
				}
			)
			.catch((error) => {
			})
	}

	return (
		<Modal
			show={props.show} onHide={handleClose}
			dialogClassName="modal-90w digitalvolunteer-wrapper"
			aria-labelledby="example-custom-modal-styling-title"
			size="md"
		>
			<Modal.Header className="theme_bg">
				<Modal.Title id="example-custom-modal-styling-title" className="mt-3 ms-3">
					Join Our Event
					<p></p>
				</Modal.Title>
				<CloseButton variant="white" onClick={handleClose} />
			</Modal.Header>
			<Modal.Body className="theme_bg">
				<Formik
					initialValues={{
						name: '',
						email: '',
						contact_number: '',
						joining_event_id: `${event_id}`,
						comments: ''
					}}
					validationSchema={ValidationSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setTimeout(() => {
							setSuccessMessage("");
							setErrorMessage("");
							formSubmitHandler(JSON.stringify(values, null, 2), resetForm, setSubmitting);
						}, 400);
					}}
				>
					{({ isSubmitting }) => (
						<Form className="form-inline">
							{successMessage ?
								<div className="alert alert-success">{successMessage}</div>
								:
								''}
							{errorMessage ?
								<div className="alert alert-danger">{errorMessage}</div>
								:
								''}


							<Field type="hidden" name="joining_event_id" value={event_id} />

							<div className="mb-3 form-group">
								<label for="Inputemail">Name *</label>
								<Field type="text" name="name" className="form-control mt-3" id="Inputemail" placeholder="Name" />
								<ErrorMessage name="name" component="div" className="alert alert-danger text-white col-12" />

							</div>
							<div className="mb-3 form-group">
								<label for="InputPhone">Email *</label>
								<Field type="text" name="email" className="form-control mt-3" id="InputPhone" placeholder="Email" />
								<ErrorMessage name="email" component="div" className="alert alert-danger text-white col-12" />

							</div>
							<div className="mb-3 form-group">
								<label for="InputAddress">Contact Number *</label>
								<Field type="text" name="contact_number" className="form-control mt-3" id="InputAddress" placeholder="Contact Number" />
								<ErrorMessage name="contact_number" component="div" className="alert alert-danger text-white col-12" />
							</div>
							<div className="mb-3 form-group ">
								<label for="floatingTextarea">Message</label>
								<Field as="textarea" className="form-control mt-3" name="comments" placeholder="Leave a comment here" id="floatingTextarea" />
								<ErrorMessage name="comments" component="div" className="alert alert-danger text-white col-12" />
							</div>

							<button type="submit" className="btn btn-info mt-4" disabled={isSubmitting}>SUBMIT</button>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
};

export default EventJoinForm;