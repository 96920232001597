import classes from './ProjectFilter.scss';
import YiLabContainer from "../../../common/YiLabContainer/YiLabContainer";
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react';

const ProjectFilter = (props) => {
    
    const [typingTimeout, setTypingTimeout] = useState(0);
    const items = props.items;

    const filterHandler = (value) => {
        if(typingTimeout){
			clearTimeout(typingTimeout);
		}

        setTypingTimeout( setTimeout(() => {
            props.filterHandler(value);
        }, 1500));

    }

    const newLocal = items.length;
    return (
        <>
            <div className="container-fluid yilab-container">
                <div className="row ">
                    <div className="col-md-6 col-lg-6 project-input-column">
                        <div className="project-input-wrapper">
                            <input type="text" className="form-control" placeholder="Search" defaultValue={props.filterText} aria-label="Search" onChange={(e)=>{filterHandler(e.target.value)}}/>
                            <span className="search_icon"><i className="fas fa-search"></i></span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 project-count">
                        {
                            (!props.isLoading)?
                                (items && (items.length > 0))?
                                    <span>Showing {items.length} project{(items.length > 1)?'s':''}</span>
                                :
                                    <span>No Projects Found</span>
                            : ''
                        }
                    </div>
                </div>
            </div>
        </>

    );
}
export default ProjectFilter;