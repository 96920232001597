import { useState } from "react";
import { Link } from "react-router-dom";
import DigitalVolunteerForm from "./ModalForms/DigitalVolunteerForm";
import SpeakAtEventForm from "./ModalForms/SpeakAtEventForm";
import SectionBoxItem from "./SectionBoxItem";

const parallaxData1 = [
    // {
    //     start: 'self',
    //     startOffset: 100,
    //     duration: 200,
    //     properties: [
    //         {
    //             startValue: 0,
    //             endValue: 1,
    //             property: 'opacity',
    //         },
    //         {
    //             startValue: 0,
    //             endValue: 1,
    //             property: 'scale',
    //         },
    //         {
    //             startValue: 75,
    //             endValue: 0,
    //             property: 'rotate',
    //         },
    //         {
    //             startValue: -175,
    //             endValue: 0,
    //             property: 'translateX',
    //         },
    //     ],
    // },
];

const parallaxData2 = [
    // {
    //     start: 'self',
    //     startOffset: 100,
    //     duration: 200,
    //     properties: [
    //         {
    //             startValue: 0,
    //             endValue: 1,
    //             property: 'opacity',
    //         },
    //         {
    //             startValue: 0,
    //             endValue: 1,
    //             property: 'scale',
    //         },
    //         {
    //             startValue: 400,
    //             endValue: 0,
    //             property: 'translateY',
    //         },
    //     ],
    // },
];

const parallaxData3 = [
    {
        start: 'self',
        startOffset: 100,
        duration: 200,
        properties: [
            // {
            //     startValue: 0,
            //     endValue: 1,
            //     property: 'opacity',
            // },
            // {
            //     startValue: 0,
            //     endValue: 1,
            //     property: 'scale',
            // },
            // {
            //     startValue: -75,
            //     endValue: 0,
            //     property: 'rotate',
            // },
            // {
            //     startValue: 175,
            //     endValue: 0,
            //     property: 'translateX',
            // },
        ],
    },
];



const SectionBox = () => {
    const [showDigitalVolunteerForm, setShowDigitalVolunteerForm] = useState(false);
    const [showSpeakAtEventForm, setShowSpeakAtEventForm] = useState(false);

    const digitalVolnteerFormVisibilityHandler = (value) => {
        setShowDigitalVolunteerForm(value);
    };

    const speakAtEventFormVisibilityHandler = (value) => {
        setShowSpeakAtEventForm(value);
    };

    return (
        <section className="section_margin_top">
            <div className="container-fluid yilab-container">
                <div className="row row-eq-height">
                    <SectionBoxItem
                        prefix="JOIN OUR"
                        title="DRR-Fellowship"
                        description="DRR-Fellowship aims to build the technical capacity of local governments to support the localization of BIPAD portal and seeks to bridge the technical gap that lies at the local level by mobilizing young people."
                        button_text="LEARN MORE"
                        button_class="btn btn-success"
                        parallaxData={parallaxData1}>
                        <a href="https://fellowship.youthinnovationlab.org/" className="mt-4 btn btn-success" target="_blank" >Learn More</a>
                    </SectionBoxItem>

                    <SectionBoxItem
                        prefix="BECOME A"
                        title="Digital Volunteer"
                        description="Join the “Learning OpenStreetMap” training to become a certified Digital Volunteer and support us in our mission to bridge to public science divide with the use of cutting-edge technological tools."
                        button_text="Join Now"
                        button_class="btn btn-danger"
                        parallaxData={parallaxData2}>
                        <Link to="#" className="mt-4 btn btn-danger" onClick={() => setShowDigitalVolunteerForm(true)}>Join Now</Link>

                    </SectionBoxItem>

                    <SectionBoxItem
                        prefix="BOOK OUR TEAM MEMBER TO"
                        title="Speak at your Event"
                        description="We have a pool of experts in the field of disaster risk reduction and management, Technical development, design, finance and data visualization. Invite our team members to speak at your event."
                        button_text="Mark Their Calender"
                        button_class="btn btn-warning"
                        parallaxData={parallaxData3}>

                        <Link to="#" className="mt-4 btn btn-warning" onClick={() => setShowSpeakAtEventForm(true)}>Mark Their Calender</Link>

                    </SectionBoxItem>

                    <DigitalVolunteerForm show={showDigitalVolunteerForm} onVisibilityChange={digitalVolnteerFormVisibilityHandler}></DigitalVolunteerForm>
                    <SpeakAtEventForm show={showSpeakAtEventForm} onVisibilityChange={speakAtEventFormVisibilityHandler}></SpeakAtEventForm>

                </div>
            </div>
        </section>
    );
};

export default SectionBox;