import { useState } from 'react';
import classes from './BlogSearch.scss';

const BlogSearch = (props) => {
	const filterKeyUpdateHandler = (e) => {
		props.onFilterKeyUpdate(e.target.value);
	};

	return (
		<div className="container-fluid yilab-container m-auto">
			<div className="input-wrapper position-relative m-auto">
				<input className="search_input" type="text" placeholder="Type what you are waiting for" name="s" onChange={(e)=>filterKeyUpdateHandler(e)}/>
				<button className="search_icon"><i className="fas fa-search"></i></button>
			</div>
		</div>
	);
};

export default BlogSearch;