import { Link } from "react-router-dom";
import LinkWithArrow from "../../../common/LinkWithArrow/LinkWithArrow";
import classes from "./PublicationItem.scss";
const PublicationItem = (props) => {

	const item = props.item;
	return (
		item ?
			<>
				<div className="col-12 col-md-4 col-lg-3 ">
					<div className="publication-image">
						<Link className="h5" to={"/publications/" + item.slug}>
							<img src={item.front_image} className="w-100" />
						</Link>
					</div>
					<div className="publication-text">
						<div className="mt-2">
							<span>{item.formatted_published_date}</span>
						</div>
						{/* <p>Sherlock Holmes</p> */}
						<Link className="h5" to={"/publications/" + item.slug}>{item.name}</Link>

					</div>
				</div>
			</>

			: ''
	);
};

export default PublicationItem;