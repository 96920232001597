import HtmlParser from "react-html-parser";
import classes from "./TeamDescription.scss";

const TeamBackground = (props) => {

    const item = props.item;
    return (
        <>
            <div className="row team-description-wrapper ">
                <div className="col-12">
                    <div className="team-description">
                        {HtmlParser(item.description)}
                    </div>
                </div>

            </div>
        </>
    );
}
export default TeamBackground;
