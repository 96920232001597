import { useState, useEffect } from 'react';
import classes from './VacancyGroup.scss';
import YiLabContainer from "../../../common/YiLabContainer/YiLabContainer";
import VacancyItem from "./VacancyItem";
import Loading from '../../../common/Loading/Loading';

const VacancyGroup = () => {
    const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/vacancy';

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItems(result.results);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, []);

	if(isLoading){
		return <Loading/>;
	}

    return (
        <div className="vacancy-listing">
            <YiLabContainer>
                <div className="row g-5" >
                    {items.map((item,index)=>
                        <VacancyItem item={item} key={"vcncy_item_"+index}></VacancyItem>
                    )}
                </div>
            </YiLabContainer>
        </div>
    );
}
export default VacancyGroup;