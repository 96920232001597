// import classes from './ProjectGroup.scss';
import { useState, useEffect } from "react";
import ProjectItem from "./ProjectItem";
import YiLabContainer from "../../../common/YiLabContainer/YiLabContainer";
import Loading from "../../../common/Loading/Loading";
import ReactPaginate from 'react-paginate';
import { useHistory, useParams } from "react-router";
import '../../../common/ReactPaginate/ReactPaginate.scss';
import ProjectFilter from "../ProjectFilter/ProjectFilter";

const ProjectGroup = () => {
    
    const pageUrl = '/projects';
    
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [hasNext, setHasNext] = useState(false);

    
    useEffect(() => {
        let limit = process.env.REACT_APP_ITEMS_PER_PAGE;
        let offset = (page * limit) - limit;
        let url = process.env.REACT_APP_API_BASE_URL + '/project?limit=' + limit + '&offset=' + offset+( filterText?('&title='+filterText) : '');
        
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    if( result.next != null ){
                        setHasNext(true);
                    }else{
                        setHasNext(false);
                    }
                    let new_items = [...items];  
                    new_items.push(...result.results);
                    setItems(new_items);
                    setIsLoading(false);
                },
                (error) => {
                    setError(error);
                }
            )
    }
    , [page,filterText]);

    const handleLoadMore = () => {
        let new_page = page+1;
        setPage(new_page);
    };

    const handleFilterUpdate = (value) => {
        setItems([]);
        setPage(1);
        setIsLoading(true);
        setFilterText(value);
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <ProjectFilter filterHandler={handleFilterUpdate} items={items} isLoading={isLoading} filterText={filterText}/>
            {isLoading?
                <Loading />
            :
                <YiLabContainer>
                    <div className="row g-5">
                        {items.map((item) =>
                            <ProjectItem item={item} key={'prog_'+item.id}></ProjectItem>
                        )}
                    </div>
                    
                    <div className="loadMore text-center mt-5">
                        { (hasNext)?
                            <button className="btn btn-sm btn-secondary" onClick={handleLoadMore}>Load More</button>
                            : ''
                        }
                    </div>
                </YiLabContainer>
            }
        </>
    );
}
export default ProjectGroup;