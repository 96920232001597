import { Link } from 'react-router-dom';
import classes from './item.scss';

const Item = (props) => {


	return (
		<div className={(props.classname ?? 'col-12 col-sm-6 col-md-4') + ' position-relative item_wrapper'}>
			{
				(!!props.external_link)?
					<a href={props.external_link} target="_blank" className="item rounded text-align-bottom" rel="noreferrer">
						<div className="row justify-content-center align-item-center gx-2">
							<div className="col-7 order-2 order-sm-1 m-auto">
								{props.children}
							</div>
							<div className="col-5 m-auto order-1 order-sm-2">
								{/* <div className="project-logo-image  ms-4"> */}
									<img src={props.first_page_image} className="w-100" alt="Project Logo"/>
								{/* </div> */}
							</div>
						</div>
					</a>
					:
					<Link to={'/projects/' + props.slug} className="item rounded text-align-bottom">
						<div className="row justify-content-center align-item-center gx-2">
							<div className="col-7 order-2 order-sm-1 m-auto">
								{props.children}
							</div>
							<div className="col-5 m-auto order-1 order-sm-2">
								{/* <div className="project-logo-image  ms-4"> */}
									<img src={props.first_page_image} className="w-100" alt='Project Logo'/>
								{/* </div> */}
							</div>
						</div>
					</Link>
			}
			

		</div>
	);
};

export default Item;