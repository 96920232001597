import SectionBubbleItem from "./SectionBubbleItem";
import classes from "./SectionBubble.scss";
import { useState, useEffect } from "react";
import Loading from "../../../common/Loading/Loading";

const SectionBubble = (props) => {

	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const url = process.env.REACT_APP_API_BASE_URL + '/team?expand=true';

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItems(result.results);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	let data = [];
	let total_amount = 0;

	items.map((item) => {
		total_amount = total_amount + item.members.length;
		data.push({
			name: item.title,
			value: item.members.length
		});
	});


	return (
		<div className="bubble_section_wrapper pb-5">
			<div className="container-fluid yilab-container">
				<div className="page_margin_left bubble_wrap d-none d-md-block">
					{data.map((d, i) => {
						return (
							<>
								<SectionBubbleItem height="150px" width="150px">
									<>
										<p>
											{d.name}<br />
											<span>{((d.value / total_amount) * 100).toFixed(2)}%</span>
										</p>
									</>
								</SectionBubbleItem>

								{((i % 2) == 0) ?
									<SectionBubbleItem height="35px" width="35px" />
									: ''}
							</>
						)
					}
					)}
				</div>

				<div className="bubble-data d-block d-md-none">
					<table className="table table-responsive">
						<thead>
							<tr>
								<th>Scope</th>
								<th>Resource</th>
							</tr>
						</thead>

						<tbody>
							{data.map((d, i) =>
								<tr>
									<td>{d.name}</td>
									<td>{((d.value / total_amount) * 100).toFixed(2)}%</td>
								</tr>
							)}

						</tbody>
					</table>
				</div>
			</div>
			<div className="container-fluid yilab-container">
				<div className="row flex-row-reverse">
					<div className="col-md-6">
						At present, our multi-disciplinary team of youth is working on research, design, technology development, capacity building and is supported by equally competent finance and administrative team. 22% of the members are in the tech team, 14% work in the research unit, 6% in finance and administration, and 5% are the creative ones in the design team. We also have 15 fellows who aim to localize DRRM at the municipal levels.
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionBubble;