import logo from './logo.svg';
import './App.scss';
import Header from './components/base/header/Header';
import Footer from './components/base/footer/Footer';
import WebRoute from './components/base/route/WebRoute';
import Background from './components/base/background/Background';
import CookieConsentForm from './components/base/CookieConsentForm/CookieConsentForm';
import { Helmet } from 'react-helmet';


function App() {
	return (
		<div className="main">
			<CookieConsentForm />
			<Background>
				<Helmet>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<meta name="theme-color" content="#000000" />
					<meta 
						name="description"
						content="Youth Innovation Lab constitutes a young team from diverse discipline, ethnicity, and geographical areas with the experiences from local, national and international arena in the field of Research, Creative Design, and Developers."
					/>
					<meta name="keyword" content="Youth, Innovation, Lab, IT, Technology, Research, Bipad, Map, Visualization, Fellowship, Digital, Volunteer"/>
				</Helmet>
				<Header />
				<WebRoute />
				<Footer />
			</Background>
		</div>
	);
}

export default App;
