import YiLabContainer from "../../../common/YiLabContainer/YiLabContainer";
import SectionText from "../../home/SectionText/SectionText";
import SectionTextTitleContent from "../../home/SectionText/SectionTextTitleContent";
import classes from "./ProjectFeature.scss";

const ProjectFeature = (props) => {
	const item = props.item;
	const problem_and_context = {
		"title": "Problem And Context",
		"body": item.problem_and_context,
		"is_muti_col": true
	};
	const our_solution = {
		"title": "Our Solution",
		"body": item.our_solution,
		"is_muti_col": true
	};
	const innovation_made = {
		"title": "Innovation Made",
		"body": item.innovative_brought,
		"is_muti_col": true
	};
	return (
		<div>
			<YiLabContainer>
				{problem_and_context.body ?
					<div className="row projectfeature-wrapper">
						<div className="col-md-6 feature-image">
							<img src={item.problem_and_context_img ?? '/images/problem.png'} />
						</div>
						<div className="col-md-6 feature-content-wrapper">
							<div className="feature-content">
								<SectionTextTitleContent title={problem_and_context.title} body={problem_and_context.body} is_multi_col={true}></SectionTextTitleContent>
							</div>
						</div>
					</div>
					: ''}

				{our_solution.body ?
					<div className="row projectfeature-wrapper row-reverse">
						<div className="col-md-6 feature-content-wrapper">
							<div className="feature-content">
								<SectionTextTitleContent title={our_solution.title} body={our_solution.body} is_multi_col={true}></SectionTextTitleContent>
							</div>

						</div>
						<div className="col-md-6 feature-image">
							<img src={item.our_solution_img ?? '/images/solution.png'} />
						</div>
					</div>
					: ""}

				{innovation_made.body ?
					<div className="row projectfeature-wrapper">
						<div className="col-md-6 feature-image">
							<img src={item.innovative_brought_img ?? '/images/innovation.png'} />
						</div>
						<div className="col-md-6 feature-content-wrapper">
							<div className="feature-content">
								<SectionTextTitleContent title={innovation_made.title} body={innovation_made.body} is_multi_col={true}></SectionTextTitleContent>
							</div>
						</div>
					</div>
					: ""}
			</YiLabContainer>
		</div >
	);
};

export default ProjectFeature;