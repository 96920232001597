import classes from './SectionListItem.scss';
import HtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import EventJoinForm from '../../EventDetail/EventJoinForm/EventJoinForm';

const SectionListItem = (props) => {

	const [eventIdToBook, seteventIdToBook] = useState('');
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = (id) => {
		seteventIdToBook(id);
		setShow(true);
	}

	return (
		<div className={"rounded list_item_wrapper mt-3 " + (props.className ? props.className : 'col-12')} >
			<div className="row gutterless event-wrapper" >
				<div className="d-none d-md-block col-12 col-md-3 m-md-auto  event-image-wrapper">
					<img src={props.image} />
				</div>
				<div className="col-md-6 my-2 d-flex align-items-center">
					<div>
						{
							props.is_featured ?
								<span className="badge badge-sm mb-2 event-tag">Featured</span>
								: ''
						}
						<h2 className="h5">
							<Link to={"/events/" + props.slug}>{props.title}</Link>
							{/* {props.title} */}
						</h2>
						<p className="list_item_paragraph">
							{HtmlParser(props.short_description)}
						</p>
					</div>
				</div>
				<div className="col-12 col-md-3 list_item_info my-4">
					<p className="me-4 d-md-flex justify-content-end text-end">

						{props.formatted_end_date_without_year ?
							<>
								{`${props.formatted_start_date_without_year} to ${props.formatted_end_date_without_year}`}
								<br/>
							</>
							:
							<>{props.formatted_start_date_without_year}<br/></>
						}
						{props.is_recurring ?
							<>
								{props.recurring_time}<br />
							</> : ''}
						{props.start_time}-{props.end_time}</p>
					{(props.status != 'Completed') ?
						<>
							{props.meeting_link ?
								<>
									<p>{props.type}</p>
									<div className="d-md-flex justify-content-end me-4" >
										<a to={props.meeting_link} target="_blank"> JOIN NOW</a>
									</div>
								</>
								:
								<div className="d-md-flex justify-content-end me-4" >
									<a onClick={() => handleShow(props.id)}>JOIN NOW</a>
								</div>

							}
							<EventJoinForm event_id={eventIdToBook} show={show} handleClose={handleClose} />
						</>
						:
						<div className="d-md-flex justify-content-end me-4" >
							<Link to={"/events/" + props.slug}>Read More</Link>
						</div>
					}

				</div>
			</div>
		</div >
	);

};

export default SectionListItem;