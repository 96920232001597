import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import classes from './SectionText.scss';
import SectionTextTitleContent from './SectionTextTitleContent';
import Plx from 'react-plx';

// animation properties 
let parallax_data = [
    // {
    //     start: 'self',
    //     startOffset: 0,
    //     duration: 100,
    //     properties: [
    //         {
    //         startValue: 0,
    //         endValue: 1,
    //         property: 'opacity',
    //         },
    //         {
    //             startValue: -400,
    //             endValue: 0,
    //             property: 'translateX',
    //         },
    //     ],
    // },
];

const SectionText = (sectionContent) => {
    const props = sectionContent.contents;
    parallax_data = props.animation ?? parallax_data;
    let parallaxMoveRtL = props.animation??(sectionContent.parallaxData??parallax_data);

    return (
        <section className={"section_text " + ' ' + (props.className ?? '') + ((!props.topMargin) ? '' : 'section_margin_top')}>
            <div className="container-fluid yilab-container">
                <div className="row section-text-row">

                    <div className="col-lg-2 col-sm-12 col-md-3 section_prefix_wrapper">
                        <Plx className='st_parallax' parallaxData={ parallaxMoveRtL } >
                            {props.prefix && <p className="section_prefix">{props.prefix}</p>}
                        </Plx>
                    </div>

                    <div className="col-12 col-lg-10 section_content_wrapper pt-0 pt-lg-4">
                    <SectionTextTitleContent animation={props.animation} title={props.title} subtitle={props.subtitle} body={props.body} subtitle_style={props.subtitle_style}>
                            {(sectionContent.child_full_width != "true") ? sectionContent.children : ''}
                        </SectionTextTitleContent>
                    </div>

                    {(sectionContent.child_full_width == "true") ?
                        <div className="col page_margin_left">
                            {sectionContent.children}
                        </div>
                        : ''}
                </div>
            </div>
        </section>
    );
};

export default SectionText;