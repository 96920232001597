import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../../common/Loading/Loading';
import PageHeader from "../../common/PageHeader/PageHeader";
import YiLabContainer from "../../common/YiLabContainer/YiLabContainer";
import SectionListItem from "../home/SectionList/SectionListItem";
const EventListing = ({ props, className }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const page_title = "Our Events";
    const page_summary = "Explore the recent and upcoming events organized by Youth Innovation Lab here. You will find the details on the what the event is about, the date, venue, and ways to join the events in this section.";

    // pagination with lazy loading
    const [hasNext, setHasNext] = useState(false);
    const [page, setPage] = useState(1);



    useEffect(() => {
        let limit = process.env.REACT_APP_ITEMS_PER_PAGE;
        let offset = (page * limit) - limit;
        let url = process.env.REACT_APP_API_BASE_URL + `/event?limit=${process.env.REACT_APP_ITEMS_PER_PAGE}&offset=${offset}`;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.next != null) {
                        setHasNext(true);
                    } else {
                        setHasNext(false);
                    }
                    let new_items = [...items, ...result.results];
                    setItems(new_items);
                    setIsLoading(false);
                },
                (error) => {
                    setError(error);
                }
            )
    }, [page]);

    if (isLoading) {
        return <Loading />;
    }

    const handleLoadMore = () => {
        let new_page = page + 1;
        setPage(new_page);
    };

    return (
        <>
            <Helmet>
				<title>YI-LAB | EVENTS </title>
				<meta name="title" content="EVENTS of YOUTH INNOVATION LAB"/>
				<meta name="description" content="Explore the recent and upcoming events organized by Youth Innovation Lab here. You will find the details on the what the event is about, the date, venue, and ways to join the events in this section."/>
				<meta name="keyword" content="youth, innovation, lab, events of yi-lab, activities of yi-lab, what's happening in yi-lab"/>
			</Helmet>

            <PageHeader title={page_title} summary={page_summary}></PageHeader>
            <YiLabContainer>

                {items.map((item) =>
                    <SectionListItem
                        image={item.image}
                        title={item.title}
                        location={item.venue}
                        formatted_start_date_without_year={item.formatted_start_date_without_year}
                        formatted_end_date_without_year={item.formatted_end_date_without_year}
                        start_time={item.start_time}
                        end_time={item.end_time}
                        short_description={item.short_description}
                        meeting_link={item.meeting_link}
                        className="col-12 col-lg-8"
                        slug={item.slug}
                        is_featured={item.is_featured}
                        status={item.status}
                        is_recurring={item.is_recurring}
                        recurring_time={item.recurring_time}
                    >
                    </SectionListItem>
                )}
                <div className="loadMore text-center mt-5">
                    {(hasNext) ?
                        <button className="btn btn-sm btn-secondary" onClick={handleLoadMore}>Load More</button>
                        : ''
                    }
                </div>
            </YiLabContainer>
        </>
    );
};
export default EventListing;