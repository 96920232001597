import { useState, useEffect } from "react";
import Loading from "../../../common/Loading/Loading";
import SectionTextTitleContent from "../SectionText/SectionTextTitleContent"
import SectionScore from "./SectionScore";
import classes from './SectionVision.scss';
const SectionVision = (VisionContent) => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [item, setItem] = useState([]);
	const url = process.env.REACT_APP_API_BASE_URL + '/our-vision';

	useEffect(() => {
		fetch(url)
			.then((res) => res.json())
			.then(
				(result) => {
					setItem(result.results);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}
		, []);

	if (isLoading) {
		return <Loading />
	}


	const props = VisionContent.contents;
	const scores = (item.length > 0) ? item[0].json_data : [];
	return (
		(item.length > 0) ?
			<section className="section_margin_top">
				<div className="container-fluid yilab-container">
					<div className="row flex-row-reverse vision-row">
						<div className="col-12 col-md-6">
							<SectionTextTitleContent title={props.title} body={props.body}>
								<SectionScore sectionLink="https://link.com" scores={scores}></SectionScore>
							</SectionTextTitleContent>
						</div>
					</div>
				</div>
			</section>
			: ''
	);
};

export default SectionVision;