import { useState, useEffect } from "react";
import { useRef } from "react";
import YiLabContainer from "../../common/YiLabContainer/YiLabContainer";
import VacancyDescription from "./VacancyDescription/VacancyDescription";
import classes from './VacancyDetail.scss';
import VacancyForm from "./VacancyForm/VacancyForm";
import SectionText from "../home/SectionText/SectionText";
import { useParams } from "react-router";
import Loading from "../../common/Loading/Loading";
import HtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
// import InputGroup from 'react-bootstrap/InputGroup';
// import { FormControl } from "react-bootstrap";

const VacancyDetail = () => {
    const { slug } = useParams();
    const [error, setError] = useState(null);
    const [item, setItem] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const url = process.env.REACT_APP_API_BASE_URL + '/vacancy/' + slug;
    const current_url = window.location.href;
    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setItem(result);
                    setIsLoading(false);
                },
                (error) => {
                    setError(error);
                }
            )
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    const tab_section = {
        "prefix": "AT YI-LAB",
        'title': item.title,
        "body": item.short_description
    };

    const toggleForm = () => {
        // this will toggle form and description 
        let new_toggle = toggle ? false : true;
        setToggle(new_toggle);
    };



    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    };

    return (
        <>
            <Helmet>
				<title>YI-LAB | {item.title} </title>
				<meta name="title" content={item.title}/>
				<meta name="description" content={item.title}/>
				<meta name="keyword" content="youth, innovation, lab, vacancies, career, opportunity, work with us, join our team"/>
			</Helmet>

            <SectionText contents={tab_section}></SectionText>
            <YiLabContainer>
                <div className="row vacancy-detail-row">
                    <div className="col-12 col-lg-9 vacancy_detail_wrap">
                        <div className={"vacancy_desc " + ((toggle == true) ? 'toggle' : '')}>
                            {/* <VacancyDescription item={item}></VacancyDescription> */}
                            {HtmlParser(item.description)}
                        </div>
                        <div className={"vacancy_form " + ((toggle == true) ? 'toggle' : '')}>
                            <VacancyForm item={item}></VacancyForm>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 mt-5 mt-lg-0">
                        <div className="apply-toogle-wrap">
                            <div className="apply-toogle-button">
                                <button onClick={() => toggleForm()}>{(toggle === false) ? 'Apply for This Job' : 'View Job Description'}</button>
                            </div>
                            <div className="">
                                <p className="mt-4 toogle-job-link">Link to this job</p>
                                {/* <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="inputGroup-sizing-link">Small</InputGroup.Text>
                                    <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-link" />
                                </InputGroup> */}
                                <div className="copy-clipboard d-flex position-relative">
                                    <input ref={textAreaRef} id="jobShareLink" type="text" class="w-100 link-input" value={current_url} aria-label="Link to share this job"></input>
                                    {document.queryCommandSupported('copy') &&
                                        <i onClick={copyToClipboard} className="far fa-copy position-absolute" aria-hidden="true">
                                            {copySuccess}
                                        </i>
                                    }
                                </div>

                            </div>
                            <div className="mt-4 d-flex">
                                <a href={"https://www.facebook.com/sharer/sharer.php?u=" + current_url} target="_blank" rel="noreferrer"><i class="fab fa-facebook-square  me-4"></i></a>
                                <a href={"https://twitter.com/intent/tweet?url=" + current_url} target="_blank" rel="noreferrer"><i class="fab fa-twitter-square me-4"></i></a>
                                <a href={"https://www.linkedin.com/shareArticle?mini=true&url=" + current_url} target="_blank" rel="noreferrer"><i class="fab fa-linkedin me-4"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </YiLabContainer>
        </>
    );
}
export default VacancyDetail;