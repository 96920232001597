import { useState, useEffect } from 'react';
import Loading from '../../common/Loading/Loading';
import PageHeader from "../../common/PageHeader/PageHeader";
import BlogBlock from "./BlogBlock/BlogBlock";
import BlogSearch from "./BlogSearch/BlogSearch";
import FeaturedBlog from "./FeaturedBlog/FeaturedBlog";
import HorizonalSimmer from '../../common/Simmer/HorizonalSimmer';
import PageSimmer from '../../common/Simmer/PageSimmer';
import { Helmet } from 'react-helmet';

const BlogPage = () => {

	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [typingTimeout, setTypingTimeout] = useState(0);

	const [hasNext, setHasNext] = useState(false);

	const [page, setPage] = useState(1);


	const filterHandler = (filterKey) => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}

		setTypingTimeout(
			setTimeout(() => {
				setItems([]);
				setPage(1);
				setFilterText(filterKey);
			}, 1500)
		);
	};

	const handleLoadMore = () => {
		let new_page = page + 1;
		setPage(new_page);
	}

	useEffect(() => {
		let limit = process.env.REACT_APP_ITEMS_PER_PAGE;
		let offset = (page * limit) - limit;
		offset = filterText ? offset : ((page == 1) ? 1 : (offset + 1));

		let url = process.env.REACT_APP_API_BASE_URL + `/article?offset=${offset}&limit=${process.env.REACT_APP_ITEMS_PER_PAGE}` + (filterText ? `&title=${filterText}` : '');
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					if (result.next != null) {
						setHasNext(true);
					} else {
						setHasNext(false);
					}
					let new_items = [...items, ...result.results];
					setItems(new_items);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, [filterText, page]);

	if (isLoading) {
		return (
			<Loading />
		);
	};

	return (
		<>
			<Helmet>
				<title>YI-LAB | BLOGS </title>
				<meta name="title" content="BLOGS of YOUTH INNOVATION LAB"/>
				<meta name="description" content="Read the news, blogs, articles and more shared by the Yi-Lab team as well as the media coverage of YI-Lab and team members here.  Click on each image to check out the publications."/>
				<meta name="keyword" content="youth, innovation, lab, blogs, news"/>
			</Helmet>


			<PageHeader title="Blog"
				summary="Read the news, blogs, articles and more shared by the Yi-Lab team as well as the media coverage of YI-Lab and team members here.  Click on each image to check out the publications."
			/>

			{/* Featured blog Section  */}
			<FeaturedBlog></FeaturedBlog>

			{/* Blog Search Section  */}
			<BlogSearch filterText={filterText} onFilterKeyUpdate={filterHandler}></BlogSearch>

			{/* Blog Blocks  */}
			{items.length > 0 ?
				<>
					{items.map((item) =>
						<BlogBlock item={item}></BlogBlock>
					)}
				</>
				: ''}

			<div className="loadMore text-center mt-5 d-flex justify-content-center">
				{(hasNext) ?
					<button className="btn btn-sm btn-secondary" onClick={handleLoadMore}>Load More</button>

					: ''
				}
			</div>
		</>
	);
};

export default BlogPage;