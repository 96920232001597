import './about.scss';
import PageHeader from "../../common/PageHeader/PageHeader";
import SectionText from '../home/SectionText/SectionText';
import { Helmet } from 'react-helmet';

const AboutPage = () => {
	const page_summary = "YI-Lab’s work is focused on technology development, disaster risk reduction, climate change, open data, entrepreneurship, data driven advocacy, youth engagement, and volunteerism. YI-Lab has a sound expertise in following six thematic sectors: Digital Mapping, Information Management System, Research and Publication, Digital Survey and Mapping, Training and Capacity Development, Governance and Policy.";
	const about_us = {
		"prefix": "YI LAB IS"
	};
	const about_features = {
		"prefix": "SIX THEMATIC SECTORS"
	};
	return (
		<>
			<Helmet>
				<title>YI-LAB | ABOUT YOUTH INNOVATION LAB </title>
				<meta name="title" content="ABOUT YOUTH INNOVATION LAB"/>
				<meta name="description" content="YI-Lab’s work is focused on technology development, disaster risk reduction, climate change, open data, entrepreneurship, data driven advocacy, youth engagement, and volunteerism. YI-Lab has a sound expertise in following six thematic sectors: Digital Mapping, Information Management System, Research and Publication, Digital Survey and Mapping, Training and Capacity Development, Governance and Policy."/>
				<meta name="keyword" content="youth, innovation, lab, disaster, risk, reduction, climate, change, open data, entrepreneurship, data driven advocacy, capacity development"/>
			</Helmet>

			<PageHeader title="What We Do?" summary={page_summary}></PageHeader>
			<SectionText contents={about_us}>
				<p className='mb-5 yilab-paragraph'>YI-Lab is a pioneer in modern Information Management System (IMS) development in Nepal and as collaborated with the Government of Government of Nepal for the development of information management system on disaster information management, human rights etc. Likewise, YI-Lab has been carrying out Digital Mapping through the engagement of Digital Volunteers. Our modules-based training “Learning OpenStreetMap” training helps the digital volunteers become certified by engaging them in our mission to bridge to public science divide.</p>
				<p className='mb-5 yilab-paragraph'>Our team of experts have developed a data collection application named Tathyanka, which is a data collection app with an integrated set of tools for building forms and collecting responses.All of our work is backed by intensive research work. Our multi-disciplinary team of experts in disaster risk, climate change, human rights, and alike explore the state of the art and come up with innovative concepts for enhancing the quality of output.Overall, we strive towards bringing cutting edge technological tools in the sector of data management, and visualization. </p>
			</SectionText>
			<SectionText contents={about_features}>
				<div className="about-feature-wrapper ms-5 mt-md-4 mt-sm-4">
					<div className="row mb-5">
						<div className="col-xl-4 col-md-6 col-12">
							<div className="row ">
								<div className="col-12">
									<div className="feature-wrapper">
										<img src="/images/Digital survey and mapping.svg" alt="logo" />
										<h2 className="small my-4"> Digital Mapping </h2>
										<p className="about-feature-description">We have expertise in using location
											datasets and visualizing them through
											interactive infographics, map-based
											applications and publications.
										</p>
									</div>
								</div>
								<div className="col-12 about-second-column">
									<div className="feature-wrapper">
										<img src="/images/Digital mapping.svg" alt="logo" />
										<h2 className="small my-4"> Digital Survey and Mapping </h2>
										<p className="about-feature-description">We provide technical assistance in
											survey applications tools, situation based
											topographical survey, photogrammetry,
											remote sensing, and contribution in
											OpenStreetMap.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-md-6 col-12">
							<div className="row ">
								<div className="col-12">
									<div className="feature-wrapper">
										<img src="/images/IMS.svg" alt="logo" />
										<h2 className="small my-4"> Information Managment System </h2>
										<p className="about-feature-description">We build information management
											systems that contribute to efficient data
											collection, visualization focusing on
											geodata collection and visualization.
										</p>
									</div>
								</div>
								<div className="col-12 about-second-column">
									<div className="feature-wrapper">
										<img src="/images/Training capacity.svg" alt="logo" />
										<h2 className="small my-4">Training and Capacity Development</h2>
										<p className="about-feature-description">We provide training for technological
											capacity transfer which includes
											providing in-person training, organizing
											workshops, audio/video tools, and
											developing user/technical manuals.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-md-6 col-12">
							<div className="row feature-row">
								<div className="col-12">
									<div className="feature-wrapper">
										<img src="/images/Research publication.svg" alt="logo" />
										<h2 className="small my-4"> Research and Publication </h2>
										<p className="about-feature-description">We have a multidisciplinary research team
											and sectorial experts in disaster risk, climate
											change, human rights, and alike with strong
											academic and experience of research and
											publications.
										</p>
									</div>
								</div>
								<div className="col-12 about-second-column">
									<div className="feature-wrapper">
										<img src="/images/government.svg" alt="logo" />
										<h2 className="small my-4">Governance and Policy</h2>
										<p className="about-feature-description">We understand Global and National
											mandates that are strongly backed by
											policies, acts. We develop tools based on
											user needs that the Government can use
											for evidence-based decision making.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</SectionText>
		</>
	);
}
export default AboutPage;