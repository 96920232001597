import { useState, useEffect } from "react";
import HtmlParser from "react-html-parser";
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import PageHeader from "../../common/PageHeader/PageHeader";
import YiLabContainer from "../../common/YiLabContainer/YiLabContainer";

const VisionStatement = (props) => {
	const [error, setError] = useState(null);
	const [item, setItem] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/our-vision';

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItem(result.results[0]);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, []);

	return (
	<>
		<PageHeader title="Vision Statement"></PageHeader>

		<YiLabContainer>
			<SectionTextTitleContent>
				{HtmlParser(item.description)}
			</SectionTextTitleContent>
		</YiLabContainer>
	</>
	);
};

export default VisionStatement;