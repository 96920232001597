import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import classes from './OSMModule.scss';
const OSMModule = (props) => {
	const osmModules = {
		"modules": [
			{
				'title': "Module I: OSM Sensitization",
				"description": "Module I of OSM mapping consists of introduction to OSM history (how the movement started, how it became the largest digital volunteer networks, and who uses the platform), OSM and humanitarian response (sharing the case study), OSM account creating and walk through, and introduction to HOT OSM tasking manager and project tasks."
			},
			{
				'title': "Module II: Mapping in OSM using JOSM and OSM tools",
				"description": "Module II covers training on OSM tools like Java OSM and collecting field data from their community and integrating them to OSM. The volunteers learn to setup JOSM and learning mapping attributes, to contribute using Tasking Manager. This module also introduces OSM tools such as Maps:Me,  OSM  Tracker, and MapSwipe, and hands-on training on field data collection and integration using  OSMTracker is covered in this module. "
			},
			{
				'title': "Module III: Innovative Approaches to Using OSM Data",
				"description": "In Module III, volunteers were trained on data download, data validation, innovative ways of using OSM data, and its application in decision making. <br> In this module, the digital volunteers get to explore platforms that that innovatively used OSM data, and are provided with hands-on training on validation and data download methods. In addition to this, they are linked to various OSM communities around the globe. <br> Lastly, as YI-Lab is an organization that fosters innovations in youths, we let people to explore innovative uses of such data and open doors for collaborations.",

			}
		],
	};

	return (
		<>
			<div className="osm_module_wrapper">
				{
					osmModules.modules.map((module, index) => (
						<div className="osm_module mb-5">
							<h2 className="page_header">
								<sup> {(index < 10) ? '0' : ''}{index + 1}</sup>
								{module.title}
							</h2>
							<p className="osm_summary">{ReactHtmlParser(module.description)}</p>
						</div>
					))
				}
			</div>
		</>
	);
};

export default OSMModule;