import React, { useState, useEffect } from 'react';
import Navigation from '../navigation/Navigation'
import Offcanvas from 'react-bootstrap/Offcanvas'
import './header.scss'
import { OffcanvasHeader, CloseButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavDropdown } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';


function Header() {
	const location = useLocation();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		handleClose();
	}, [location])

	return (

		<div className="site-wrapper">
			<header className="site-header">
				<div className="container-fluid yilab-container">
					<div className="row justify-content-center align-items-center position-relative">
						<div className="col-6 col-md-1">
							<div className="brand">
								<Link to="/"><img src="/images/logo.png" alt="logo" /></Link>
							</div>
						</div>
						<div className="col-9 col-md-9 d-none d-md-block header-menu-col">
							<div className="main-navigation d-lg-flex d-none">
								<ul className="main-menu">
									<li className="menu-item">
										<NavDropdown
											id="nav-dropdown-dark-example"
											title="About Us"
											menuVariant="dark"
										>
											<NavDropdown.Item href="/about">What We Do</NavDropdown.Item>
											<NavDropdown.Item href="/team">Our Team</NavDropdown.Item>
										</NavDropdown>
									</li>
									<li className="menu-item">
										<Link to="/projects">Our initiative</Link>
									</li>
									<li className="menu-item">
										<Link to="/blogs">Blog</Link>
									</li>
									<li className="menu-item">
										<Link to="/events">Events</Link>
									</li>
									<li className="menu-item">
										<Link to="/publications">Publications</Link>
									</li>
									<li className="menu-item">
										<Link to="/osm">Digital Volunteers</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-6 col-md-2">
							<div className="header-menubar" id="main">
								<a className="openbtn" onClick={handleShow}>
									<img src="/images/header-menu.svg" />
								</a>
								<Offcanvas placement="end"
									show={show} onHide={handleClose}>
									<Offcanvas.Header>
										<Offcanvas.Title>
											<div className="brand">
												<Link to="/"><img src="/images/logo.png" alt="logo" /></Link>
											</div>
										</Offcanvas.Title>
										<CloseButton variant="white" onClick={handleClose} />
									</Offcanvas.Header>
									<Offcanvas.Body>
										<div className="container mt-0 mt-md-5">
											<div className="row justify-content-center">
												<div className='col-12 col-xl-8'>
													<div className='row g-5 row-eq-height'>
														<div className="col-6 col-md-4 ">
															<div className="offcanvas-outline p-4 h-100">
																<div className=" d-flex justify-content-center">
																	<Link to="/osm"><img src="/images/Digital volunteers.svg" alt="logo" className="offcanvas-icons" /></Link>
																</div>
																<Link to="/osm" className="d-flex justify-content-center">Digital Volunteers</Link>
															</div>
														</div>
														<div className="col-6 col-md-4 ">
															<div className="offcanvas-outline p-4 h-100">
																<div className="d-flex justify-content-center">
																	<Link to="/projects"><img src="/images/our initiation.svg" alt="logo" className="offcanvas-icons" /></Link>
																</div>
																<Link to="/projects" className="d-flex justify-content-center">Our Initiations</Link>
															</div>
														</div>
														<div className="col-6 col-md-4 ">
															<div className="offcanvas-outline p-4 h-100">
																<div className="d-flex justify-content-center">
																	<Link to="/blogs">
																		<img src="/images/blogs.svg" alt="logo" className="offcanvas-icons" />
																	</Link>
																</div>
																<Link to="/blogs" className="d-flex justify-content-center">Blogs</Link>
															</div>
														</div>
														<div className="col-6 col-md-4 ">
															<div className="offcanvas-outline p-4 h-100">
																<div className="d-flex justify-content-center">
																	<Link to="/events"><img src="/images/events.svg" alt="logo" className="offcanvas-icons" /></Link>
																</div>
																<Link to="/events" className="d-flex justify-content-center">Events</Link>
															</div>
														</div>
														<div className="col-6 col-md-4 ">
															<div className="offcanvas-outline p-4 h-100">
																<div className="d-flex justify-content-center">
																	<Link to="/team"><img src="/images/teams.svg" alt="logo" className="offcanvas-icons" /></Link>
																</div>
																<Link to="/team" className="d-flex justify-content-center">Teams</Link>
															</div>
														</div>
														<div className="col-6 col-md-4 ">
															<div className="offcanvas-outline p-4 h-100">
																<div className="d-flex justify-content-center">
																	<Link to="/about"><img src="/images/about.svg" alt="logo" className="offcanvas-icons" /></Link>
																</div>
																<Link to="/about" className="d-flex justify-content-center">About Us</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Offcanvas.Body>
								</Offcanvas>
							</div>
						</div>

					</div>
				</div>
			</header >
		</div >

	);
}

export default Header;