import { Link } from 'react-router-dom';
import classes from './RecommendedEvent.scss';

const RecommendedEvent = (props) => {
    const item = props.item;
    return (
        <div className="row mt-4">
            <div className="col-md-4">
                <img src={item.image} alt="" className="w-100" />
            </div>
            <div className="col">
                <Link to={"/events/" + item.slug}>
                    {item.title}
                </Link>
                <p className="me-4 d-flex mt-2 justify-content-between">
                    {item.formatted_start_date_without_year} to {item.formatted_end_date_without_year}
                    <br />
                    <div>
                        {item.start_time}-{item.end_time}
                    </div>
                </p>
            </div>
        </div>
    );
}
export default RecommendedEvent;