import { useState, useEffect } from "react";
import SectionText from "../home/SectionText/SectionText";
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import TeamDescription from "./TeamDescription/TeamDescription";
import styles from "./TeamDetail.scss";
import YiLabContainer from '../../common/YiLabContainer/YiLabContainer';
import Loading from "../../common/Loading/Loading";
import { useParams } from "react-router";
import HtmlParser from "react-html-parser";
import Plx from "react-plx/lib/Plx";
import { Helmet } from "react-helmet";

// animation properties 
const parallax_data = [
	{
		start: 'self',
		startOffset: 0,
		duration: 200,
		properties: [
			{
				startValue: (window.innerWidth >= 1024) ? (100) : 0,
				endValue: 0,
				property: 'translateY',
			}
		],
	},
];
const parallax_data_image = [
	{
		start: 'self',
		startOffset: 0,
		duration: 200,
		properties: [
			{
				startValue: (window.innerWidth >= 1024) ? (-200) : 0,
				endValue: 0,
				property: 'translateY',
			},
			{
				startValue: (window.innerWidth >= 1024) ? (-100) : 0,
				endValue: 0,
				property: 'translateX',
			},
			{
				startValue: (window.innerWidth <= 3000) ? 0 : 1,
				endValue: 1,
				property: 'opacity',
			}
		],
	},
];

const parallax_data_description = [
	{
		start: 'self',
		startOffset: 0,
		duration: 200,
		properties: [
			{
				startValue: 0,
				endValue: 1,
				property: 'opacity',
			}
		],
	},
];

const TeamDetail = (props) => {

	const { slug } = useParams();
	const [error, setError] = useState(null);
	const [item, setItem] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/team-member/' + slug;

	useEffect(() => {
		fetch(url)
			.then((res) => res.json())
			.then(
				(result) => {
					setItem(result);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}
		, []);

	if (isLoading) {
		return <Loading />;
	}

	const section_title = {
		"prefix": "MEET",
		"title": item.name,
		"subtitle": item.position,
		"subtitle_style": "h5 mb-4",
		"topMargin": false,
		"className": "teamdetail pt-4"
	};

	return (
		<>
			<Helmet>
				<title>YI-LAB | TEAM - {item.name} </title>
				<meta name="title" content={item.name} />
				<meta name="description" content={item.name} />
				<meta name="keyword" content="youth, innovation, lab, projects, initiatives" />
			</Helmet>
			<div className={"" + styles.Teambackground}>
				<section className={"section_text teamdetail pt-4"}>
					<div className="container-fluid yilab-container">
						<div className="row section-text-row">

							<div className="col-lg-2 col-sm-12 col-md-3 section_prefix_wrapper">
								<Plx className='st_parallax' parallaxData={parallax_data} >
									<p className="section_prefix">MEET</p>
								</Plx>
							</div>

							<div className="col-12 col-lg-10 section_content_wrapper pt-0 pt-lg-4">
								<Plx className='st_parallax' parallaxData={parallax_data} >
									<h1 className={"sectiontextcontent-header  page_header"}>{HtmlParser(item.name)}</h1>
									<p className={props.subtitle_style ?? 'h5'}>{HtmlParser(item.position)}</p>
								</Plx>


								{/* {props.children} */}
								<div className="row mt-5 team-detail-row">
									<div className="col-12 col-lg-6 team-description">
										<Plx parallaxData={parallax_data_description}>
											<TeamDescription item={item}></TeamDescription>
										</Plx>
									</div>
									<div className="col-12 col-lg-6 position-relative team-detail-image">
										<Plx className='st_parallax' parallaxData={parallax_data_image} >
											<img src={item.image} alt={item.name} className="w-100" />
										</Plx>
										{/* <img src="/images/teambackground.png" alt={item.name} className="w-100" /> */}
										<Plx parallaxData={parallax_data_description}>
											<div className="row mt-5 mb-5">
												<div className="col-md-12 team-detail-icons">
													<div className="d-flex">
														{/* Links  */}
														{item.twitter_link ?
															<a href={item.twitter_link} title="share on twitter" target="_blank">
																<img className="m-3" src="/images/twitter.svg" alt="share on twitter" />
															</a>
															: ''}
														{item.facebook_link ?
															<a href={item.facebook_link} target="_blank" title="share on facebook">
																<img className="m-3" src="/images/facebook.svg" alt="share on facebook" />
															</a>
															: ''}
														{item.linkedin_link ?
															<a href={item.linkedin_link} target="_blank" title="share on linkedin">
																<img className="m-3" src="/images/linkdein.svg" />
															</a>
															: ''}
													</div>
												</div>
											</div>
										</Plx>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <SectionText contents={section_title}>
					

				</SectionText> */}
			</div>
		</>
	);
};

export default TeamDetail;