import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProjectImpactChart = (props) => {

	const chartData = props.chartData;

	const fill = ["red","blue","red","blue","red"];
	return (
		<div className="row gx-5 mt-5">

			{chartData.map((data,index)=> 
				{
					let [key, value] = Object.entries(data)[0];

					return(
					<div className="col-md-3">
						<CircularProgressbar 
							value={parseInt(value.replace("%",""))} 
							text={`${value}`} 
							strokeWidth={5} 
							styles={{
								text:{
									fontFamily:"Bebas Neue",
									fontSize: "1.2rem",
									fill:"#fff"
								},
								path:{
									stroke: `${fill[index]}`
								},
								trail:{
									stroke: '#182634'
								},
								background: {
									fill: '#000',
								}
							}}
							/>
						<p className="mt-4 text-center"><strong>{key}</strong></p>
					</div>
					);
				}
			)}
			
		</div>
	);
};

export default ProjectImpactChart;