import { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import classes from './DigitalVolunteerForm.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
	name: Yup.string()
		.required(),
	email: Yup.string()
		.required('Email is required'),
	phone_number: Yup.number()
		.required(),
	address: Yup.string()
		.required('Address is required'),
	designation_and_organization: Yup.string()
		.required('Designation and Organization is required'),
	university: Yup.string(),
	osm_id: Yup.string(),
	osm_level: Yup.string().required('This field is required'),
	past_participation: Yup.boolean()
});

const DigitalVolunteerForm = (props) => {
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	let show = props.show;
	let global_response = "";
	const url = process.env.REACT_APP_API_BASE_URL + '/digital-volunteer/';

	const formSubmitHandler = (values, resetForm, setSubmitting) => {
		// POST request using fetch inside useEffect React hook
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: values
		};
		fetch(url, requestOptions)
			.then(
				(response) => {
					global_response = response;
					return response.json();
				}
			)
			.then(
				(result) => {

					if (global_response.ok) {
						setSuccessMessage("Request received successfully. We'll get back to you soon.");
						resetForm();
						setSubmitting(false);
					} else {
						for (const r in result) {
							setErrorMessage(result[r][0]);
							break;
						}
					}
				}
			)
			.catch((error) => {
			})
	}

	const visibilityHandler = (value) => {
		setSuccessMessage('');
		setErrorMessage('');
		props.onVisibilityChange(value);
	}

	return (

		<Modal
			show={show}
			onHide={() => visibilityHandler(false)}
			dialogClassName="modal-90w digitalvolunteer-wrapper"
			aria-labelledby="example-custom-modal-styling-title"
			size="lg"
		>
			<Modal.Header className="theme_bg">
				<Modal.Title id="example-custom-modal-styling-title" className="mt-3 ms-3">
					Digital Volunteer Form
					<p></p>
				</Modal.Title>
				<CloseButton variant="white" onClick={() => visibilityHandler(false)} />
			</Modal.Header>
			<Modal.Body className="theme_bg">
				<Formik
					initialValues={{
						name: '',
						email: '',
						address: '',
						phone_number: '',
						address: '',
						designation_and_organization: '',
						university: '',
						osm_id: '',
						osm_level: '',
						past_participation: ''
					}}
					validationSchema={ValidationSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setTimeout(() => {
							setSuccessMessage("");
							setErrorMessage("");
							formSubmitHandler(JSON.stringify(values, null, 2), resetForm, setSubmitting);
						}, 400);
					}}>
					{({ isSubmitting }) => (
						<Form className="form-inline">
							{successMessage ?
								<div className="alert alert-success">{successMessage}</div>
								:
								''}
							{errorMessage ?
								<div className="alert alert-danger">{errorMessage}</div>
								:
								''}
							<p className="h5 general-info mt-4 mb-2">General Information</p>
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="mb-2 form-group">
										{/* <label for="dv_name">1. Full Name</label> */}
										<Field name="name" className="form-control mt-3" id="dv_name" placeholder="Full Name" />
									</div>
								</div>

								<div className="col-12 col-md-6">
									<div className="mb-2 form-group">
										{/* <label for="dv_email">2. Email Address</label> */}
										<Field type="email" name="email" className="form-control mt-3" id="dv_email" placeholder="Email Address" />
										<ErrorMessage name="email" component="div" className="alert alert-danger text-white col-12" />
									</div>
								</div>

								<div className="col-12 col-md-6">
									<div className="mb-2 form-group">
										{/* <label for="phone_number">3. Phone Number</label> */}
										<Field type="text" name="phone_number" className="form-control mt-3" id="phone_number" placeholder="Phone Number" />
										<ErrorMessage name="phone_number" component="div" className="alert alert-danger text-white col-12" />
									</div>
								</div>

								<div className="col-12 col-md-6">
									<div className="mb-2 form-group">
										{/* <label for="address">4. Address</label> */}
										<Field type="text" name="address" className="form-control mt-3" id="address" placeholder="Address" />
										<ErrorMessage name="address" component="div" className="alert alert-danger text-white col-12" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="mb-2 form-group">
										{/* <label for="designation_and_organization">5. Designation/Organization</label> */}
										<Field type="text" name="designation_and_organization" className="form-control mt-3" id="designation_organization" placeholder="Designation/Organization" />
										<ErrorMessage name="designation_and_organization" component="div" className="alert alert-danger text-white col-12" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="mb-2 form-group">
										{/* <label for="university">6. If you are a student, provide name of your university.</label> */}
										<Field type="text" name="university" className="form-control mt-3" id="university" placeholder="University" />
										<ErrorMessage name="university" component="div" className="alert alert-danger text-white col-12" />
									</div>
								</div>
							</div>

							<p className="h5 general-info mt-5 mb-2">OSM Related</p>
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="mb-2 form-group">
										{/* <label for="osm_id">7. Do you have OpenStreetMap ID? If yes please enter your OSM ID</label> */}
										<Field type="text" name="osm_id" className="form-control mt-3" id="osm_id" placeholder="OSM ID" />
										<ErrorMessage name="osm_id" component="div" className="alert alert-danger text-white col-12" />
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-md-6 mt-4">
									<div className="mb-3 form-group">
										<label className="mb-2">Are you ...</label>
										<ErrorMessage name="osm_level" component="div" className="alert alert-danger text-white col-12" />
										<div className="">
											<div className="form-check">
												<Field className="form-check-input" type="radio" name="osm_level" id="newToOSM" value="New to OSM" />
												<label className="form-check-label" for="newToOSM">
													New to OSM
												</label>
											</div>
											<div className="form-check">
												<Field className="form-check-input" type="radio" name="osm_level" id="intermediateMapper" value="Intermediate mapper" />
												<label className="form-check-label" for="intermediateMapper">
													Intermediate mapper
												</label>
											</div>
											<div className="form-check">
												<Field className="form-check-input" type="radio" name="osm_level" id="advanceMapper" value="Advance Mapper" />
												<label className="form-check-label" for="advanceMapper">
													Advance mapper
												</label>
											</div>
											<div className="form-check">
												<Field className="form-check-input" type="radio" name="osm_level" id="expertMapper" value="Expert Mapper" />
												<label className="form-check-label" for="expertMapper">
													Expert mapper
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12 col-md-6 mt-4">
									<div className="mb-3 form-group">
										<label for="exampleInputAddress" className="mb-2">Have you participated in "Learning OpenStreetMap"  training provided by Youth Innovation Lab?</label>
										<ErrorMessage name="past_participation" component="div" className="alert alert-danger text-white col-12" />
										<div className="">
											<div className="form-check">
												<Field className="form-check-input" type="radio" name="past_participation" id="pp_true" value="true" defaultChecked />
												<label className="form-check-label" for="pp_true">
													Yes
												</label>
											</div>
											<div className="form-check">
												<Field className="form-check-input" type="radio" name="past_participation" id="pp_false" value="false" />
												<label className="form-check-label" for="pp_false">
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>


							<button type="submit" className="btn btn-info mt-4" disabled={isSubmitting}>SUBMIT</button>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>

	);

};

export default DigitalVolunteerForm;