import { Helmet } from "react-helmet";
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import TeamForm from "../team/TeamForm/TeamForm";

const Contact = () => {
	return (
		<>
			<Helmet>
				<title>YI-LAB | CONTACT YOUTH INNOVATION LAB </title>
				<meta name="title" content="CONTACT YOUTH INNOVATION LAB"/>
				<meta name="description" content="publications and research reports produced by YI-Lab team"/>
				<meta name="keyword" content="youth, innovation, lab, contact, location, map, message"/>
			</Helmet>

			<div className="container-fluid yilab-container page_margin_left section_margin_top">
				<div className="row join-team-row">
					<div className="col-md-5">
						<SectionTextTitleContent
							title="Leave your Detail"
							body="Fill up the form with your details and leave your comments if you are interested to work with us."
							is_multi_col={true}
						/>
					</div>
					<div className="col-md-7 team-form-wrapper mt-5 mt-md-0">
						<SectionTextTitleContent title="Get in touch with us."></SectionTextTitleContent>
						<TeamForm />
					</div>
				</div>
			</div>
		</>
	);
}

export default Contact;