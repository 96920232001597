import HtmlParser from "react-html-parser";
import SectionText from "../../pages/home/SectionText/SectionText";
import classes from './PageHeader.scss';

const PageHeader = ({ title, summary, subtitle = "", prefix = "", suffix = "" }) => {
	return (
		<section className="page_header_wrapper">
			<div className="container-fluid yilab-container page_margin_left">
				{prefix ? <p className="page_header_prefix">{prefix}</p> : ''}
				<h1 className="page_header">{title}</h1>
				<p className="page_summary">
					{ HtmlParser(summary)}
				</p>
				<p className="page_summary_suffix">
					{suffix}
				</p>
			</div>
		</section>

	);
};

export default PageHeader;