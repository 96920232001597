
import { useState } from 'react';
import './VacancyForm.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const SUPPORTED_FORMATS = ['application/pdf'];
const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(),
    email: Yup.string()
        .required('Email is required')
        .email(),
    phone: Yup.string()
        .required('Phone Number is required'),
    years_of_experience: Yup.number()
        .required('Years of Experience is required'),
    education: Yup.string()
        .required('Education Info is required'),
    cv: Yup.mixed()
        .test('fileSize', 'File too large. Max: 2MB is allowed', (value) => value === null || (value && value.size <= 2097152)) //2Mib
        .test(
            'fileFormat',
            'Please upload cv in pdf file format.',
            (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type))
        )
        .required('CV is required')
});


const VacancyForm = (props) => {

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const url = process.env.REACT_APP_API_BASE_URL + '/vacancy-form/';
    const item = props.item;
    let global_response = "";

    const formSubmitHandler = (formData, resetForm, setSubmitting) => {

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch(url, requestOptions)
            .then(
                (response) => {
                    global_response = response;
                    return response.json();
                }
            )
            .then(
                (result) => {

                    if (global_response.ok) {
                        setSuccessMessage("Request received successfully. We'll get back to you soon.");
                        resetForm();
                    } else {
                        if( result.validation_errors ){
                            // return result.validation_errors;
                            for (const r in result.validation_errors) {
                                setErrorMessage(r, result.validation_errors[r][0]);
                            }
                        } else if( result.error ) {
                            setErrorMessage(result.error);
                        }else if (result.detail ){
                            setErrorMessage(result.detail)
                        }else{
                            setErrorMessage("Couldn't process request. Please try again");
                        }
                        // for (const r in result) {
                        //     setErrorMessage(result[r][0]);
                        //     break;
                        // }
                    }
                    setSubmitting(false);
                }
            )
            .catch((error) => {
            })
    }

    return (
        <div>
            <Formik
                initialValues={{
                    applied_vacancy_id: item.id,
                    name: '',
                    email: '',
                    phone: '',
                    education: '',
                    years_of_experience: '',
                    cv: null
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setTimeout(() => {
                        setSuccessMessage("");
                        setErrorMessage("");

                        let formData = new FormData();    //formdata object
                        for (const key in values) {
                            formData.append(`${key}`, values[key]);
                        }
                        formSubmitHandler(formData, resetForm, setSubmitting);

                    }, 400);
                }}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className="form-inline" encType="multipart/form-data">
                        {successMessage ?
                            <div className="alert alert-success">{successMessage}</div>
                            :
                            ''}
                        {errorMessage ?
                            <div className="alert alert-danger">{errorMessage}</div>
                            :
                            ''}
                        <Field type="hidden" name="applied_vacancy_id" defaultValue={item.id} />
                        <div className="row">
                            <div className="mb-4">
                                <h3>Apply for the Job</h3>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 vacancy-form">
                                    <label for="nameInput">Full Name*</label>
                                    <Field type="text" className="form-control" name="name" placeholder="First Name" id="nameInput" />
                                    <ErrorMessage name="name" component="div" className="alert alert-danger col-12" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 vacancy-form" >
                                    <label for="emailInput">Email*</label>
                                    <Field type="email" className="form-control" name="email" placeholder="Email Address" id="emailInput" />
                                    <ErrorMessage name="email" component="div" className="alert alert-danger text-white col-12" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 vacancy-form">
                                    <label for="phoneInput">Phone*</label>
                                    <Field type="text" className="form-control" name="phone" placeholder="Phone" id="phoneInput" />
                                    <ErrorMessage name="phone" component="div" className="alert alert-danger text-white col-12" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 vacancy-form">
                                    <label for="address">Address*</label>
                                    <Field type="text" className="form-control" name="address" placeholder="Address" id="address" />
                                    <ErrorMessage name="address" component="div" className="alert alert-danger text-white col-12" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 vacancy-form">
                                    <label for="educationInput">Education*</label>
                                    <Field type="text" className="form-control" name="education" placeholder="Education" id="educationInput" />
                                    <ErrorMessage name="education" component="div" className="alert alert-danger text-white col-12" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 vacancy-form" >
                                    <label for="yearsOfExp">Years of Experience*</label>
                                    <Field type="number" className="form-control" name="years_of_experience" placeholder="Years of Experience" id="yearsOfExp" />
                                    <ErrorMessage name="years_of_experience" component="div" className="alert alert-danger text-white col-12" />
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                        <div className="d-md-flex mt-4">
                            <div class="mb-3 vacancy-form" >
                                {/* <Field type="file" class="form-control" name="cv" placeholder="CV" id="cvInput" /> */}
                                <label for="cvInput" className="mb-3">Upload your CV*</label><br />
                                <input type="file" class="" name="cv" placeholder="CV" id="cvInput"
                                    onChange={(e) => setFieldValue("cv", e.currentTarget.files[0])}
                                />

                                <ErrorMessage name="cv" component="div" className="alert alert-danger text-white col-12" />
                            </div>
                            <div class="mb-3 vacancy-form" >
                                {/* <Field type="file" class="form-control" name="cv" placeholder="CV" id="cvInput" /> */}
                                <label for="mlInput" className="mb-3">Upload your Motivation Letter*</label><br />
                                <input type="file" class="" name="ml" placeholder="CV" id="mlInput"
                                    onChange={(e) => setFieldValue("ml", e.currentTarget.files[0])}
                                />

                                <ErrorMessage name="ml" component="div" className="alert alert-danger text-white col-12" />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-info mt-4" disabled={isSubmitting}>Apply</button>
                    </Form>
                )}
            </Formik>
        </div >
    );
}
export default VacancyForm;