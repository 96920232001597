import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import LinkWithArrow from "../LinkWithArrow/LinkWithArrow";
import './RecommendedReadings.scss';

const RecommendedReadings = (props) => {

	const items = props.items;
	return (
		<div className="row mt-5">
			{items.map((item) =>
				<div className={'col-xl-3 col-md-6 my-4 mt-md-0'}>
					<div className="recommended_item_wrap mt-2 p-3 h-100">
						<span className="badge badge-pill bg-info mb-3">{item.type}</span>
						<h2 className="h5"><Link to={"/blogs/" + item.slug}>{item.title}</Link></h2>
						<p>
							{HtmlParser(item.short_desc)}
						</p>
						<LinkWithArrow link_to={"/" + ((item.type.toLowerCase()=='article')?'blog':item.type.toLowerCase()) + "s/" + item.slug} className="no-effect p-0" link_text="READ MORE"></LinkWithArrow>
					</div>
				</div>
			)}
		</div>
	);

}

export default RecommendedReadings;