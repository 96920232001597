import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from './ProjectDonor.scss';
import SectionText from "../../home/SectionText/SectionText";

const ProjectDonor = (props) => {

    const item = props.item;

    let slides_to_show = 4;

    slides_to_show = (item.donors.length <= slides_to_show) ? item.donors.length - 1 : slides_to_show;

    let settings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToShow: slides_to_show,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 0,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2500,
        cssEase: "linear",
        variableWidth: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const section_about_us = {
        "prefix": "DONORS",
        // "title": "About",
        "body": "",
        "classname": "dummy"
    };

    return (
        item.donors.length > 0 ?
            <>
                <SectionText contents={section_about_us} >
                    {/* <div className="container-fluid yilab-container mb-5"> */}
                    {/* <Slider {...settings}>
                            {(item.donors.length > 0) ?
                                item.donors.map((donor) =>
                                    <div className="projectdonor-image">
                                        <a href={donor.external_link} target="_blank" title="donor">
                                            < img src={donor.logo} alt="donor_logo" />
                                        </a>
                                    </div>
                                )
                                : ''}
                        </Slider> */}
                    <div className="donor-wrapper row">
                        {(item.donors.length > 0) ?
                            item.donors.map((donor) =>
                                <div className="col-12 col-md-3">
                                    <div className="projectdonor-image">
                                        <a href={donor.external_link} target="_blank" title="donor">
                                            < img src={donor.logo} alt="donor_logo" />
                                        </a>

                                    </div>
                                    <a href={donor.external_link} target="_blank" title="donor" className="d-flex justify-content-center mt-3 donor-name">
                                        {donor.name}
                                    </a>
                                </div>
                            )
                            : ''}
                    </div>
                    {/* </div> */}
                </SectionText>

            </>
            : ''
    );


};

export default ProjectDonor;
