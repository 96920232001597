// import classes from './about.scss';
import { useState, useEffect } from "react";
import YiLabContainer from "../../common/YiLabContainer/YiLabContainer";
import ListView from "../../common/ListView/ListView";
import Loading from "../../common/Loading/Loading";
import { useParams } from "react-router";
import EventFact from "./EventFact/EventFact";
import HtmlParser from "react-html-parser";
import RecommendedEvent from "./RecommendedEvent/RecommendedEvent";
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import RecommendedReadings from "../../common/RecommendedReadings/RecommendedReadings";
import { Helmet } from "react-helmet";

const EventDetail = () => {

	let { event } = useParams();

	const [error, setError] = useState(null);
	const [item, setItem] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/event/' + event;

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItem(result);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, [event]);


	if (isLoading) {
		return <Loading />;
	}
	return (
		item ?
			<>
				<Helmet>
					<title>YI-LAB | {item.title} </title>
					<meta name="title" content={item.title}/>
					<meta name="description" content={item.title}/>
					<meta name="keyword" content="youth, innovation, lab, vacancies, career, opportunity, work with us, join our team"/>
				</Helmet>

				<YiLabContainer>
					<EventFact item={item} key={'evt_fact_'+item.id}></EventFact>
					<div className="row">
						<div className="col-12 col-md-8  event-description mt-5 pe-0 pe-md-5">
							{HtmlParser(item.description)}
						</div>
						{(item.recommended_events.length > 0) ?
							<div className="col-md-4 blog_sidebar_right p-md-0 pt-3 pt-md-3 mt-5">
								<h4>Other Events</h4>
								{
									item.recommended_events.map(ritem =>
										<RecommendedEvent item={ritem} key={"rec_evt_"+item.id}></RecommendedEvent>
									)
								}
							</div>
							: ''}
					</div>
					
					<div class="related_readings mt-5">
						{(item.related_readings.length > 0) ?
								<SectionTextTitleContent title="Related Readings" >
									<RecommendedReadings items={item.related_readings} />
								</SectionTextTitleContent>
							: ''
						}
					</div>
				</YiLabContainer>
			</>
			: ''
	);
}
export default EventDetail;