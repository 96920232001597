import { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './NewsletterForm.scss';
import * as Yup from 'yup';


const ValidationSchema = Yup.object().shape({
	email: Yup.string()
		.required()
		.email('Email is required'),
	agree_policy: Yup.boolean()
		.required('Please Agree to our Term before Subscribe')
});

const NewsletterForm = () => {

	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const url = process.env.REACT_APP_API_BASE_URL + '/newsletter/';
	let global_response = "";

	const formSubmitHandler = (values, setSubmitting, resetForm) => {
		// POST request using fetch inside useEffect React hook
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: values
		};
		fetch(url, requestOptions)
			.then(
				(response) => {
					global_response = response;
					return response.json();
				}
			)
			.then(
				(result) => {
					if (global_response.ok) {
						setSuccessMessage("Thank you for subscribing our newsletter");
					} else {
						for (const r in result) {
							setErrorMessage(result[r][0]);
							break;
						}
					}
					resetForm();
					setSubmitting(false);
				}
			)
			.catch((error) => {
			})
	}

	return (
		<Formik
			initialValues={{
				email: '',
				agree_policy: ''
			}}
			validationSchema={ValidationSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setTimeout(() => {
					setSuccessMessage("");
					setErrorMessage("");

					formSubmitHandler(JSON.stringify(values, null, 2), setSubmitting, resetForm);

				}, 400);
			}}
		>

			{({ isSubmitting }) => (
				<Form className="form-inline">
					{successMessage ?
						<div className="alert alert-success mt-3">{successMessage}</div>
						:
						''}
					{errorMessage ?
						<div className="alert alert-danger">{errorMessage}</div>
						:
						''}
					<div className="checkbox">
						<Field type="checkbox" name="agree_policy" id="flexCheckDefault" className="form-check-input" />
						<span> I give my consent that my personal
							information can be collected and used according to the Privacy Policy.</span>
					</div>
					<ErrorMessage name="agree_policy" component="div" className="alert alert-danger text-white col-12" />
					<div className="newsletter-form mt-5">
						<div className="form-group  mb-2 d-flex position-relative">
							<Field type="text" name="email" className="form-control" placeholder="Enter your email" aria-label="Enter your email" aria-describedby="basic-addon1" />
							<button type="submit" className="btn btn-primary newsletter-button" disabled={isSubmitting}>SUBSCRIBE</button>
						</div>
						<div>
							<ErrorMessage name="email" component="div" className="alert alert-danger text-white col-12" />
						</div>
					</div>
				</Form>

			)}

		</Formik>
	);

};

export default NewsletterForm;