import React, { useState, useEffect } from 'react';
import Loading from '../../../common/Loading/Loading';
import YiLabContainer from '../../../common/YiLabContainer/YiLabContainer';
import Item from "./Item";
import classes from './ItemGroup.scss';
import Plx from 'react-plx';

const parallaxData = [
	// {
	// 	start: 'self',
	// 	startOffset: 50,
	// 	duration: 100,
	// 	properties: [
	// 		{
	// 			startValue: 0,
	// 			endValue: 1,
	// 			property: 'opacity',
	// 		},
	// 		{
	// 			startValue: -300,
	// 			endValue: 0,
	// 			property: 'translateX',
	// 		},
	// 	],
	// },
];

const ItemGroup = () => {
	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/project?limit=3&is_featured=true';

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItems(result.results);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	return (
		items.length > 0 ?
			<YiLabContainer>
				<div className="row d-flex justify-content-end ">
					<div className="col-12  col-xl-8 project-highlight-column-wrappper">
						<div className="item-group section_margin_top">
							<Plx className='st_parallax' parallaxData={parallaxData} >
								<div className="row row-eq-height item-group-wrapper">
									<h2 className="small mb-4"> PROJECT HIGHLIGHTS </h2>
									{
										items.map((item) =>
											<Item
												{ ...item }
												key={item.id}
												slug={item.slug}
												first_page_image={item.first_page_image}
												
											>{item.title}</Item>
										)
									}
								</div>
							</Plx>
						</div>
					</div>
				</div>
			</YiLabContainer>
			: ''

	);
};

export default ItemGroup;