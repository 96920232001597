// import classes from './about.scss';
import SectionText from "../home/SectionText/SectionText";
import PageHeader from "../../common/PageHeader/PageHeader";
import VacancyGroup from "./VacancyGroup/VacancyGroup";
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import TeamForm from "../team/TeamForm/TeamForm";
import { useState, useEffect } from "react";
import Loading from "../../common/Loading/Loading";
import { Helmet } from "react-helmet";


const VacancyPage = () => {
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [isLoding, setIsLoading] = useState(true);
    const url = process.env.REACT_APP_API_BASE_URL + '/vacancy';

    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result.results);
                    setIsLoading(false);
                },
                (error) => {
                    setError(error);
                }
            )
    }, []);

    const page_title = "Openings at Youth Innovation Lab";
    const page_summary = "We call for job applications from young and suitable candidates for following positions";

    if(isLoding){
        return <Loading />;
    }

    return (
        <>
            <Helmet>
				<title>YI-LAB | VACANCIES </title>
				<meta name="title" content="VACANCIES"/>
				<meta name="description" content="Career opportunities in Yi-lab"/>
				<meta name="keyword" content="youth, innovation, lab, vacancies, career, opportunity, work with us, join our team"/>
			</Helmet>

            <div>
                <PageHeader title={page_title} summary={page_summary}></PageHeader>
                {items.length > 0 &&
                    <VacancyGroup></VacancyGroup>
                }

                {items.length <= 0 &&
                    <div className="yilab-container">
                        <div className="row join-team-row">
                            <div className="col-md-5">
                                <SectionTextTitleContent
                                    title="Leave your Detail"
                                    body="There is no career opening right now please, fill up the form with your details and leave your comments if you are interested to work with us."
                                    is_multi_col={true}
                                >
                                </SectionTextTitleContent>

                            </div>
                            <div className="col-md-7 team-form-wrapper mt-5 mt-md-0">
                                <SectionTextTitleContent title="get in touch with us."></SectionTextTitleContent>
                                <TeamForm />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
export default VacancyPage;