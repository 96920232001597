import { Link } from 'react-router-dom';
import classes from './LinkWithArrow.scss';
const LinkWithArrow = ({ link_to, link_text, className }) => {
	return (
		<div className="link-with-arrow my-3">
			<Link to={link_to ?? '/'} className={className ? className : 'normal-effect'}>
				{link_text ?? 'View More'}
				<svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 20 13">
					<path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward" d="M20.629,11.5a.869.869,0,0,0-.007,1.246l4.224,4.123H8.771a.88.88,0,1,0,0,1.76H24.839l-4.224,4.123A.875.875,0,0,0,20.622,24a.916.916,0,0,0,1.271-.007l5.724-5.619h0a.987.987,0,0,0,.188-.278.822.822,0,0,0,.069-.339.871.871,0,0,0-.257-.616l-5.724-5.619A.9.9,0,0,0,20.629,11.5Z" transform="translate(-7.875 -11.251)" fill="#fff" />
				</svg>
			</Link>
		</div>

	);
};

export default LinkWithArrow;