import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import Loading from "../../common/Loading/Loading";
import PageHeader from "../../common/PageHeader/PageHeader";
import YiLabContainer from "../../common/YiLabContainer/YiLabContainer"
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import BlogContentBlock from "./BlogContentBlock/BlogContentBlock";

const BlogDetail = (props) => {

	let {slug} = useParams();

	const [error, setError] = useState(null);
	const [item, setItem] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/article/'+slug;

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItem(result);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, [slug]);
	

	if(isLoading){
		return <Loading />;
	}
	return (
		<>

			<Helmet>
				<title>YI-LAB | {item.title} </title>
				<meta name="title" content={item.title}/>
				<meta name="description" content={item.title}/>
				<meta name="keyword" content="youth, innovation, lab, blogs, news"/>
			</Helmet>

			<PageHeader 
				prefix={item.formatted_published_date}
				title={item.title}
				summary={item.short_description}
				suffix={"By "+item.author}
			></PageHeader>
			<YiLabContainer>
				<BlogContentBlock item={item}></BlogContentBlock>
			</YiLabContainer>
		</>
	);
};

export default BlogDetail;