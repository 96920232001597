import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Variable for Validation 
const ValidationSchema = Yup.object().shape({
	name: Yup.string().required(),
	email: Yup.string().required('Email is required').email(),
	phone_number: Yup.string().required('Contact is required'),
	comment: Yup.string().required('Message is required')
});

const TeamForm = (props) => {

	// States 
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	// constats 
	const url = process.env.REACT_APP_API_BASE_URL + '/contact-us/';

	// variables
	let global_response = "";

	// dependency functions 
	const formSubmitHandler = (values, resetForm, setSubmitting) => {
		// POST request using fetch inside useEffect React hook
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: values
		};
		fetch(url, requestOptions)
			.then(
				(response) => {
					global_response = response;
					return response.json();
				}
			)
			.then(
				(result) => {
					if (global_response.ok) {
						setSuccessMessage("Thank you for you interest");
					} else {
						for (const r in result) {
							setErrorMessage(result[r][0]);
							break;
						}
					}
					resetForm();
					setSubmitting(false);
				}
			)
			.catch((error) => {
			})
	}


	return (
		<Formik
			initialValues={{
				name: '',
				email: '',
				phone_number: '',
				comment: '',
			}}
			validationSchema={ValidationSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setTimeout(() => {
					setSuccessMessage("");
					setErrorMessage("");
					formSubmitHandler(JSON.stringify(values, null, 2), resetForm, setSubmitting);
				}, 400);
			}}
		>
			{({ isSubmitting }) => (
				<Form className="form-inline mt-4">
					{successMessage ?
						<div className="alert alert-success">{successMessage}</div>
						:
						''}
					{errorMessage ?
						<div className="alert alert-danger">{errorMessage}</div>
						:
						''}
					<div className="mb-3 vacancy-form form-floating">
						<Field type="text" className="form-control" name="name" placeholder="First Name" id="firstNameInput" />
						<label for="firstNameInput">Full Name</label>
						<ErrorMessage name="name" component="div" className="alert alert-danger text-white col-12 mt-3" />

					</div>

					<div className="mb-3 vacancy-form form-floating" >
						<Field type="email" className="form-control" name="email" placeholder="Email Address" id="emailInput" />
						<label for="emailInput">Email</label>
						<ErrorMessage name="email" component="div" className="alert alert-danger text-white col-12 mt-3" />

					</div>

					<div className="mb-3 vacancy-form form-floating" >
						<Field type="text" className="form-control" name="phone_number" placeholder="Contact" id="phoneInput" />
						<label for="phoneInput">Contact</label>
						<ErrorMessage name="phone_number" component="div" className="alert alert-danger text-white col-12 mt-3" />

					</div>

					<div className="mb-3 form-floating comment-section">
						<Field as="textarea" className="form-control" name="comment" placeholder="Leave a comment here" id="commentTextArea" />
						<label for="commentTextArea">Message</label>
						<ErrorMessage name="comment" component="div" className="alert alert-danger text-white col-12 mt-3" />

					</div>
					<button type="submit" className="btn btn-info mt-3" disabled={isSubmitting}>SUBMIT</button>

				</Form>
			)}

		</Formik>

	);
};

export default TeamForm;