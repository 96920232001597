import { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import Loading from '../../../common/Loading/Loading';
import classes from './Filter.scss'
const Filter = (props) => {
	const [filterKeyword, setFilterKeyword] = useState('');
	const [error, setError] = useState(null);
	const [filterYear, setFilterYear] = useState('');
	const [filterPubType, setFilterPubType] = useState('');
	const [typingTimeout, setTypingTimeout] = useState(0);
	const [publicationTypes, setPublicationTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const url = process.env.REACT_APP_API_BASE_URL + '/publication-type';

	const items = props.items;

	const yearArray = [];
	const current_year = new Date().getFullYear();

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setPublicationTypes(result.types);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, [url]);

	useEffect(() => {
		if (!isLoading) {

			search();
		}
	}, [filterYear, filterKeyword, filterPubType]);


	if (isLoading) {
		return <Loading />;
	}

	const filterKeyChangeHandler = (value) => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		setTypingTimeout(
			setTimeout(() => {
				props.filterActive(true);
				setFilterKeyword(value);
			}, 1500)
		);
	}


	const filterYearChangeHandler = (value) => {
		props.filterActive(true);
		setFilterYear(value);
	}

	const filterPubTypeChangeHandler = (value) => {
		props.filterActive(true);
		setFilterPubType(value);
	}

	const search = () => {
		props.search(filterKeyword, filterYear, filterPubType);
	}

	for (let i = current_year; i >= 2015; i--) {
		yearArray.push(<option value={i} key={"arrop_" + i}>{i}</option>);
	}

	return (
		<div >
			<div className="container-fluid yilab-container">
				<div className="row filter-wrapper border-bottom">
					<div className='col-md-8 col-12 d-md-flex d-inline mt-4'>
						<div className="col-md-4 col-12 filter-column mb-3 mb-md-0">
							<div className="filter-input-wrapper">
								<input type="text" className="form-control" placeholder="Search" aria-label="Search Keyword" onChange={(e) => filterKeyChangeHandler(e.target.value)} />
								<span className="search_icon ps-2"><i className="fas fa-search"></i></span>
							</div>
						</div>
						<div className="col-md-4 col-12 filter-column mb-3 mb-md-3">
							<div className="filter-input-wrapper">
								<select className="form-control" onChange={(e) => filterYearChangeHandler(e.target.value)}>
									<option value="">Select Year</option>
									{yearArray}
								</select >
								<span className="search_icon"><i className="fas fa-chevron-down"></i></span>
							</div >
						</div >
						<div className="col-md-4 filter-column mb-3 mb-md-0">
							<div className="filter-input-wrapper">
								{/* <input type="text" className="form-control" placeholder="Filter by author" aria-label="Server" />
							 */}
								<select className="form-control" onChange={(e) => { filterPubTypeChangeHandler(e.target.value) }}>
									<option value="">Type</option>
									{
										(publicationTypes.length > 0) ?
											publicationTypes.map(pt =>
												<option value={pt}>{pt}</option>
											)
											: ''
									}
								</select>
								<span className="search_icon"><i className="fas fa-chevron-down"></i></span>
							</div>
						</div>
					</div>
					<div className='col-md-4'>
						{/* <div className='publication-length'>
							{items.length > 0 ?
								<span>Showing {items.length} publication{(items.length > 1) ? 's' : ''}</span>
								:
								<span>No publications Found</span>
							}
						</div> */}
					</div>
				</div >

			</div >
			{/* <div className="container-fluid yilab-container">
				<div className="row date-wrapper">
					<div className="col-md-6 col-lg-6 ">
						{items.length > 0 ?
							<span>Showing {items.length} publication{(items.length > 1) ? 's' : ''}</span>
							:
							<span>No publications Found</span>
						}
					</div>
					<div className="col-md-6 col-lg-6 date-column">
						<Dropdown>
							<Dropdown.Toggle variant="success" id="dropdown-basic">
								Dropdown Button
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
								<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
								<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
			</div> */}
		</div >

	);
};

export default Filter;