import { Route, Switch } from 'react-router-dom';
import HomePage from '../../pages/home/HomePage';
import AboutPage from '../../pages/about/AboutPage';
import OSMPage from '../../pages/OSM/OSMPage';
import TeamPage from '../../pages/team/TeamPage';
import Publication from '../../pages/publication/Publication';
import PublicationDetail from '../../pages/PublicationDetail/PublicationDetail';
import FinancePage from '../../pages/finance/FinancePage';
import BlogPage from '../../pages/blog/BlogPage';
import ProjectDetail from '../../pages/ProjectDetail/ProjectDetail';
import TeamDetail from '../../pages/TeamDetail/TeamDetail';
import BlogDetail from '../../pages/BlogDetail/BlogDetail';
import Project from '../../pages/project/Project'
import VacancyListing from '../../pages/VacancyListing/VacancyListing'
import VacancyDetail from '../../pages/VacancyDetail/VacancyDetail';
import EventDetail from '../../pages/EventDetail/EventDetail';
import EventListing from '../../pages/EventListing/EventListing';
import VisionStatement from '../../pages/VisionStatement/VisionStatement';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import ErrorPage from '../../pages/404/ErrorPage';
import Contact from '../../pages/Contact/Contact';


const WebRoute = () => {
	return (
		<ScrollToTop>
			<Switch>
				<Route path="/" exact>
					<HomePage></HomePage>
				</Route>
				<Route path="/about" exact>
					<AboutPage></AboutPage>
				</Route>
				<Route path="/osm" exact>
					<OSMPage></OSMPage>
				</Route>
				<Route path="/team" exact>
					<TeamPage></TeamPage>
				</Route>

				<Route exact path="/publications">
					<Publication></Publication>
				</Route>

				<Route exact path="/publications/:publication">
					<PublicationDetail></PublicationDetail>
				</Route>

				{/* <Route exact path="/finance">
					<FinancePage></FinancePage>
				</Route> */}
				
				<Route exact path="/contact">
					<Contact />
				</Route>

				<Route exact path="/vision-statement">
					<VisionStatement />
				</Route>

				<Route exact path="/blogs">
					<BlogPage></BlogPage>
				</Route>

				<Route exact path="/blogs/:slug">
					<BlogDetail></BlogDetail>
				</Route>

				{/* Project Routes  */}
				<Route exact path="/projects">
					<Project></Project>
				</Route>

				<Route path="/projects/:project" exact>
					<ProjectDetail></ProjectDetail>
				</Route>

				<Route path="/team/:slug" exact>
					<TeamDetail></TeamDetail>
				</Route>

				<Route exact path="/vacancy">
					<VacancyListing></VacancyListing>
				</Route>

				<Route exact path="/vacancy/:slug">
					<VacancyDetail></VacancyDetail>
				</Route>
				<Route exact path="/events">
					<EventListing></EventListing>
				</Route>
				<Route exact path="/events/:event">
					<EventDetail></EventDetail>
				</Route>
				<Route exact path="/error-page">
					<ErrorPage></ErrorPage>
				</Route>
			</Switch>
		</ScrollToTop>
	);
}

export default WebRoute;