import { useState, useEffect } from 'react';
import PageHeader from '../../common/PageHeader/PageHeader';
import YiLabContainer from '../../common/YiLabContainer/YiLabContainer';
import SectionTextTitleContent from '../home/SectionText/SectionTextTitleContent';
import PublicationContent from './PublicationContent/PublicationContent';
import PublicationItem from '../publication/PubicationGroup/PublicationItem';
import classes from "./PublicationDetail.scss"
import { useParams } from 'react-router';
import Loading from '../../common/Loading/Loading';
import Item from '../home/ItemGroup/Item';
import { Helmet } from 'react-helmet';
const PublicationDetail = (props) => {

	let { publication } = useParams();

	const [error, setError] = useState(null);
	const [item, setItem] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const url = process.env.REACT_APP_API_BASE_URL + '/publication/' + publication;

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItem(result);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, [publication]);


	if (isLoading) {
		return <Loading />;
	}
	// console.log(item);

	return (
		<>
			{/* Page Heading  */}
			{/* <PageHeader
				prefix={item.formatted_published_date}
			title={item.name}
			summary={item.description ?? ""}
			></PageHeader> */}
			<Helmet>
				<title>YI-LAB | {item?.name} </title>
				<meta name="title" content={item?.name}/>
				<meta name="description" content={item?.name}/>
				<meta name="keyword" content="youth, innovation, lab, publication, research"/>
			</Helmet>
			

			{/* Publication Detail  */}
			<YiLabContainer>
				{/* <SectionTextTitleContent>
				</SectionTextTitleContent> */}
				<PublicationContent item={item}></PublicationContent>
			</YiLabContainer>

			{/* Recommended Section  */}
			{/* {(item.recommended_publications.length > 0) ?
				<YiLabContainer>
					<div className="mt-5 mt-md-0">
						<SectionTextTitleContent title="Recommended Reading" />
					</div>
					<div className="row g-5 recommended-publication">
						{item.recommended_publications.map((item, index) =>
							(index < 3) ?
								<PublicationItem item={item}></PublicationItem>
								: ''
						)}
					</div>

				</YiLabContainer>
				: ''
			} */}
		</>
	);
};

export default PublicationDetail;