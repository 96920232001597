// ScrollToTop.jsx
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from 'react-router-dom';


const ScrollToTop = (props) => {
  	const location = useLocation();
	const [prevScroll, setPreviousScroll] = useState(0);
	const [nextScroll, setNextScroll] = useState(0);
	const [ locationKeys, setLocationKeys ] = useState([]);
	const history = useHistory()

  	useEffect(() => {
		  
		if (history.action === 'PUSH') {
			setPreviousScroll(window.scrollTop);
			window.scroll({
				top : 0,
				left : 0, 
				behavior :'instant'});
		}
	  
		if (history.action === 'POP') {
			if (locationKeys[1] === location.key) {
			  	setLocationKeys(([ _, ...keys ]) => keys)
				setNextScroll(window.scrollTop);

			  	// Handle back event
				window.scroll({
					top : prevScroll,
					left : 0, 
					behavior :'instant'});

			} else {
				setLocationKeys((keys) => [ location.key, ...keys ])
				setPreviousScroll(window.scrollTop);
			  	// Handle forward event
				window.scroll({
					top : nextScroll,
					left : 0, 
					behavior :'instant'});
	  
			}
		}
		
  	}, [location]);
  	return <>{props.children}</>
};

export default ScrollToTop;