import HtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import LinkWithArrow from '../../../common/LinkWithArrow/LinkWithArrow';
import classes from './ProjectItem.scss';

const ProjectItem = (props) => {
    const item = props.item;
    return (
        <div className="col-md-4 col-lg-4 project-item-column">
            <div className="projectlisting-wrapper">
                <div className="project-image">
                    {/* <img src="/images/default.jpg" alt="" className="w-100" /> */}
                    {/* <img src={item.first_page_image} alt={item.title} className="w-100" /> */}
                </div>
                <div className="project-text d-flex">
                    {/* <p>{item.author_name}</p> */}
                    <img src={item.first_page_image} alt={item.title} />

                    {
                        !!item.external_link?
                            <a href={item.external_link} target="_blank" className="h5" rel="noreferrer">{item.title}</a>
                        :
                            <Link to={"/projects/" + item.slug} className="h5">{item.title}</Link>
                    }
                
                </div>
                <p className="mb-4">{item.about_project.replace(/(<([^>]+)>)/ig, '')}</p>
                {
                    !!item.external_link?
                        <div className="link-with-arrow my-3">
                            <a href={item.external_link} target="_blank" className="no-effect" rel='noreferrer'>
                                VIEW MORE
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 20 13">
                                    <path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward" d="M20.629,11.5a.869.869,0,0,0-.007,1.246l4.224,4.123H8.771a.88.88,0,1,0,0,1.76H24.839l-4.224,4.123A.875.875,0,0,0,20.622,24a.916.916,0,0,0,1.271-.007l5.724-5.619h0a.987.987,0,0,0,.188-.278.822.822,0,0,0,.069-.339.871.871,0,0,0-.257-.616l-5.724-5.619A.9.9,0,0,0,20.629,11.5Z" transform="translate(-7.875 -11.251)" fill="#fff" />
                                </svg>  
                            </a>
                        </div>
                    :
                        <LinkWithArrow link_to={"/projects/" + item.slug} className="no-effect"></LinkWithArrow>
                }
            </div>
        </div>
    );
}
export default ProjectItem;