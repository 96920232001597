// import classes from './about.scss';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import YiLabContainer from "../../../common/YiLabContainer/YiLabContainer";
import './EventFact.scss';
import './EventFactForm.js';
import EventFactForm from "./EventFactForm.js";
import EventJoinForm from "../EventJoinForm/EventJoinForm";

const EventFact = (props) => {
    const item = props.item;
    const [show, setShow] = useState(false);

    const format_date = (date) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if( (date===null) || (date === "") ){
            return '';
        }
        let given_date = new Date(date);
        let new_date = months[given_date.getMonth()]+' '+given_date.getDate()+', '+given_date.getFullYear();

        return new_date;
    }

    let formatted_start_date = format_date(item.start_date);
    let formatted_end_date = format_date(item.end_date);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="row mt-5">
            <div className="col-12 col-md-9 d-flex align-items-center">
                <div className="event-fact-wrapper w-100">
                    <div className="event-header mb-5">
                        <h1>{item.title}</h1>
                    </div>
                    <div className="row event-detail">
                        <div className="col-12 col-md-6">
                            <p className="h5 mb-4">DETAIL</p>
                            <p>{formatted_end_date ? 'From' : 'Date'}: <span>{formatted_start_date}</span></p>
                            {formatted_end_date ?
                                <p>To: <span>{formatted_end_date}</span></p>
                                : ''}
                            <p>Time: <span>{item.start_time}-{item.end_time}</span></p>
                            {(item.venue && !item.is_private) ?
                                <p>Venue: <span>{item.venue}</span></p>
                                : ''}
                            {item.status ?
                                <p>Status: <span className="badge badge-sm bg-info">{item.status}</span></p>
                                : ''}
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="h5 mb-4">ORGANIZERS</p>
                            {item.organizers.map((organizer) =>
                                <p>{organizer}</p>
                            )}

                        </div>
                    </div>

                    {(item.status != "Completed") ?
                        item.meeting_link ?
                            <a href={item.meeting_link} className="btn btn-info mt-4" target="_blank">Register for {item.type ?? 'Event'}</a>
                            :
                            <button type="submit" className="btn btn-info mt-4" onClick={handleShow} >Register for Event</button>
                        :
                        <>
                        </>
                    }

                    {/* Modal here  */}
                    <EventJoinForm event_id={item.id} show={show} handleClose={handleClose} key={"jef_" + item.id} />
                </div>
            </div>
            <div className="col-12 col-md-3 mt-5 mt-md-0">
                <div className="">
                    <figure>
                        <img src={item.image} alt={item.title} className="w-100" />
                        <figcaption>
                            {item.photo_credit ?
                                "Photo Credit : " + item.photo_credit
                                : ''
                            }
                        </figcaption>
                    </figure>

                </div>
            </div>
        </div>

    );
}
export default EventFact;