import Plx from 'react-plx/lib/Plx';
import Loading from '../../common/Loading/Loading';
import YiLabContainer from '../../common/YiLabContainer/YiLabContainer';
import ImageGroup from './ImageGroup/ImageGroup';
import ImageGroupAdjustable from './ImageGroupAdjustable/ImageGroupAdjustable';
import ItemGroup from './ItemGroup/ItemGroup';
import SectionBox from './SectionBox/SectionBox';
import SectionList from './SectionList/SectionList';
import SectionVision from './SectionScore/SectionVision';
import SectionText from './SectionText/SectionText';
const HomePage = () => {
	const section_1 = {
		"className": "",
		"prefix": "YI-LAB IS",
		"title": "Bridging science to people and policy through innovation.",
		// "subtitle" : "Youth led organization with the <br> average age of 29.",
		// "body" : "Show projects"
		"animation": []
	};

	const section_2 = {
		"className": "",
		"prefix": "PUBLICATION",
		"title": "Find our publication here",
		// "subtitle" : "Youth led organization with the <br> average age of 29.",
		"body": "All our works are backed by intensive research. Read the latest research papers, reports, and articles related to disaster risk reduction, technological innovations, information, and data management and more published by Youth Innovation Lab here. Click on the image to check out each of the publication."
	};
	const section_mission = {
		"className": "",
		"prefix": "Our Mission",
		"title": "YI-Lab's Mission",
		// "subtitle" : "Youth led organization with the <br> average age of 29.",
		"body": "To equip youth with the cutting-edge technological tools to bridge science to people and policy through innovation. "
	};
	const section_vision = {
		"className": "",
		"title": "Our Vision",
		"body": "To be a pioneer in creating conducive environment for youth to solve complex issues using technology. By 2030 YI-Lab will produce, "
	};

	const section_whats_happening = {
		"className": "",
		"title": "What's <br> Happening?",
		"body": "Explore the recent and upcoming events organized by Youth Innovation Lab here. You will find the details on the what the event is about, the date, venue, and ways to join the events in this section. Click here to check out all our recent and upcoming events."
	};
	const section_blog = {
		"className": "",
		"prefix": "OUR COVERAGE",
		"title": "WHAT'S HAPPENING",
		// "subtitle" : "Youth led organization with the <br> average age of 29.",
		"body": "Read the news, blogs, articles and more shared by the Yi-Lab team as well as the media coverage of YI-Lab and team members here.  Click on each image to check out the publications."
	};
	// const logo_animate_data = [];
	const logo_animate_data = [
		{
			start: 'self',
			startOffset: -10,
			duration: 500,
			properties: [
				{
					startValue: 200,
					endValue: 0,
					property: 'translateX'
				},
				{
					startValue: 100,
					endValue: 0,
					property: 'translateY'
				},
				{
					startValue: 1,
					endValue: 0.5,
					property: 'scale'
				},
				{
					startValue: 1,
					endValue: 0,
					property: 'opacity'
				},
			],
		},
	];

	return (
		<>
			{/* <Loading/> */}
			<YiLabContainer>
				<div className="row justify-content-center d-block d-md-none">
					<div className="col-md-12 text-center">
						<img src="/images/yilab_logo_animation.gif" className="w-100" />
					</div>
				</div>
				<div className="logo_animation d-none d-md-block">
					<div className="row row-eq-height">
						<div className="col-12 col-md-6 tagline_wrap_outer">
							<div className="tagline_wrap">
							</div>
						</div>
						<div className="col-12 col-md-5 text-end">
							<Plx className='st_parallax' parallaxData={logo_animate_data} >
								<img src="/images/yilab_logo_animation.gif" className="w-100" />
							</Plx>
						</div>

					</div>
				</div>
			</YiLabContainer>

			{/* Youth Innovation Lab is  */}

			<SectionText contents={section_1}>
			</SectionText>

			<ItemGroup></ItemGroup>

			{/* Our Mission  */}
			<SectionText contents={section_mission}>
			</SectionText>

			{/* Our Vision  */}
			<SectionVision contents={section_vision}></SectionVision>

			{/* Box section  */}
			<SectionBox></SectionBox>

			{/* Featured Publications  */}
			<SectionText contents={section_2}>
				<ImageGroup></ImageGroup>
			</SectionText>

			{/* What's happening  */}
			<SectionList contents={section_whats_happening}></SectionList>

			{/* What's Happening  */}
			<SectionText contents={section_blog}>
			</SectionText>
			<ImageGroupAdjustable>
			</ImageGroupAdjustable>
		</>
	);
}

export default HomePage;