import classes from "./SectionBubbleItem.scss";

const SectionBubbleItem = (props) => {
	const animations = [
		'animate_t1', 'animate_t2', 'animate_t3'
	];
	return(
		<div className="bubble_item">
			<div className={"bubble_outer d-inline-block " + " " +animations[Math.floor(Math.random()*animations.length)]} style={{height:props.height, width:props.width}}>
				<div className="text_wrapper span">
					{props.children}
				</div>
			</div>	
		</div>
	);
};

export default SectionBubbleItem;