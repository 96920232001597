import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './ImageGroupAdjustable.scss';
import LinkWithArrow from '../../../common/LinkWithArrow/LinkWithArrow';
import YiLabContainer from '../../../common/YiLabContainer/YiLabContainer';
import Loading from '../../../common/Loading/Loading';
import HtmlParser from 'react-html-parser';
const ImageGroupAdjustable = () => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const url = process.env.REACT_APP_API_BASE_URL + '/article?limit=5';

    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result.results);
                    setIsLoading(false);
                },
                (error) => {
                    setError(error);
                }
            )
    }, []);


    if (isLoading) {
        return <Loading />;
    }

    return (
        <div> <YiLabContainer>
            <div className="row">
                <div className="col-12 adjustible_div">
                    <div className="row">
                        {items.map((item) =>
                            <div className="ad_image col-12 col-md-6 col-xl-4 mb-4">
                                <div className="position-relative overflow-hidden">
                                    <Link to={'/blogs/' + item.slug}>
                                        <img src={item.image} />
                                        <div className="blog-showcase p-4">
                                            <small className="badge badge-sm bg-info p-1 d-table">{item.category}</small>
                                            <Link className="h5 m-0 mt-1" to={"/blogs/" + item.slug}>{item.title}</Link>
                                            <p className="blog-showcase-description pt-2">
                                                {HtmlParser(item.short_description)}
                                            </p>
                                            {/* <LinkWithArrow link_to={'/blogs/'+item.slug} link_text="Read More" /> */}
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        )}
                    </div>


                    <div className="ad_image ad_image_link p-0 py-sm-4 mt-3">
                        <LinkWithArrow link_to="/blogs" link_text="Learn More"></LinkWithArrow>
                    </div>


                </div>
            </div>
        </YiLabContainer>
        </div>
    );
};

export default ImageGroupAdjustable;