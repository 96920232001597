import React, { useState, useEffect } from 'react';

import SectionTextTitleContent from '../SectionText/SectionTextTitleContent';
import LinkWithArrow from '../../../common/LinkWithArrow/LinkWithArrow';
import { Link } from 'react-router-dom';
import SectionListItem from './SectionListItem';
import classes from './SectionList.scss';
import Loading from '../../../common/Loading/Loading';

const SectionList = (SectionListContent) => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [items, setItems] = useState([]);
	const url = process.env.REACT_APP_API_BASE_URL + '/event?limit=4&is_osm_event=false';

	useEffect(() => {
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					setItems(result.results);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
				}
			)
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	const props = SectionListContent.contents;
	return (
		<section className="section_dark section_margin_top">
			<div className="container-fluid yilab-container">
				<div className="row event-row">
					<div className="col-12 col-xl-4 m-auto event-col">
						<SectionTextTitleContent title={props.title} body={props.body}>
							<div className="mt-3 mt-md-0">
								<LinkWithArrow link_to="/events" link_text="All events"></LinkWithArrow>
							</div>
						</SectionTextTitleContent>
					</div>
					<div className="col-12 col-xl-8">
						<div className="row">
							{items.map((item) =>
								<SectionListItem
									id={item.id}
									image={item.image}
									title={item.title}
									location={item.venue}
									formatted_start_date_without_year={item.formatted_start_date_without_year}
									formatted_end_date_without_year={item.formatted_end_date_without_year}
									start_time={item.start_time}
									end_time={item.end_time}
									short_description={item.short_description}
									meeting_link={item.meeting_link}
									slug={item.slug}
									is_featured={item.is_featured}
									status={item.status}
									is_recurring={item.is_recurring}
									recurring_time={item.recurring_time}
								></SectionListItem>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SectionList;