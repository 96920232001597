import { useState, useEffect } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Loading from "../../../../common/Loading/Loading";
import classes from './SpeakAtEventForm.scss';

const ValidationSchema = Yup.object().shape({
	speaker_id: Yup.string()
		.required("Speaker is required"),
	name_of_organization: Yup.string()
		.required('Organization Name is required'),
	date_and_time: Yup.date()
		.min(new Date(), 'Please choose possible Date & Time')
		.required(),
	email: Yup.string()
		.required('Email is required')
		.email(),
	event_location: Yup.string()
		.required('Event Location is required'),
	description: Yup.string()
		.required('Description about Event is required')
});

const SpeakAtEventForm = (props) => {

	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [speakers, setSpeakers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	
	const [step, setStep] = useState(1);
	
	const [selectedSpearkerDepart, setSelectedSpeakerDepart] = useState('');
	const [selectedSpeaker, setSelectedSpeaker] = useState();
	const [showDepartSelect, setShowDepartSelect] = useState(true);
	

	const url = process.env.REACT_APP_API_BASE_URL + '/speaker-event/';
	const team_get_url = process.env.REACT_APP_API_BASE_URL + '/team?expand=true';



	let global_response = "";
	let processed_team_by_depart = [];

	let show = props.show;

	useEffect(() => {
		fetch(team_get_url)
			.then(res => res.json())
			.then(
				(result) => {
					setSpeakers(result.results);
					setIsLoading(false);
				}
			)
	}, []);

	const formSubmitHandler = (values, resetForm, setSubmitting) => {
		// POST request using fetch inside useEffect React hook
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: values
		};
		fetch(url, requestOptions)
			.then(
				(response) => {
					global_response = response;
					return response.json();
				}
			)
			.then(
				(result) => {
					if (global_response.ok) {
						setSuccessMessage("Request received successfully. We'll get back to you soon.");
						setSelectedSpeaker('');
						setSelectedSpeakerDepart('');
						setShowDepartSelect(true);
						setStep(1);
						resetForm();

					} else {
						for (const r in result) {
							setErrorMessage(r+' '+result[r][0]);
							break;
						}
					}
					setSubmitting(false);
				}
			)
			.catch((error) => {
			})
	}

	// Handles on department change show members in speaker field relatively 
	const departmentSelectHandler = (value) => {
		setShowDepartSelect(false);
		setSelectedSpeakerDepart(value);
	}

	const visibilityHandler = (value) => {
		setSuccessMessage('');
		setErrorMessage('');
		props.onVisibilityChange(value);
	};

	const nextForm = () => {
		let new_step = step + 1;
		setStep(new_step);
	}

	const prevForm = () => {
		let new_step = step - 1; 
		setStep(new_step);
	}

	return (
		<Modal
			show={show}
			onHide={() => visibilityHandler(false)}
			dialogClassName="modal-90w speakatevent-wrapper"
			size="lg"
			aria-labelledby="example-custom-modal-styling-title"
		>
			<Modal.Header className="theme_bg">
				<Modal.Title id="example-custom-modal-styling-title" className="mt-3 ms-3 mb-3">
					{(step == 1) ? 'Select Speaker' : 'Events & Organization Detail'}
				</Modal.Title>
				<CloseButton variant="white" onClick={() => visibilityHandler(false)} />
			</Modal.Header>
			<Modal.Body className="theme_bg">
				{isLoading ? <Loading /> :
					<Formik
						initialValues={{
							category: '',
							speaker_id: selectedSpeaker,
							name_of_organization: '',
							event_location: '',
							email: '',
							description: ''
						}}
						validationSchema={ValidationSchema}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							setTimeout(() => {
								setSuccessMessage("");
								setErrorMessage("");
								formSubmitHandler(JSON.stringify(values, null, 2), resetForm, setSubmitting);
							}, 400);
						}}
					>

						{({ isSubmitting, setFieldValue }) => (
							<Form className="form-inline position-relative step_form_wrap">
								{successMessage ?
									<div className="alert alert-success pb-3">{successMessage}</div>
									:
									''}
								{errorMessage ?
									<div className="alert alert-danger">{errorMessage}</div>
									:
									''}
								<div className={"row speak_form_row form1 "+((step != 1)?'d-none':'')}>
									
									<div className={"col-12 "+ ((showDepartSelect)?'':'d-none')}>
										<label>Select Department</label>
										<div className="row">
											{speakers.map((department) => {
												processed_team_by_depart[department.slug] = department.members;
												return (
												<div className="col-md-4 justify-content-center align-item-center my-3">
													<div className={"dept_wrapper text-center p-4 "+((selectedSpearkerDepart==department.slug)?'selected':'')} onClick={(e) => departmentSelectHandler(department.slug)}>
														{department.title}
													</div>
												</div>
												);
											})}
										</div>
									</div>
									{
										(showDepartSelect==false)?
										<div className="col-12">
											<label>Selected Department <small className="badge badge-xm bg-warning p-1 change_btn" onClick={ () => setShowDepartSelect(true) }>Edit</small></label><br/>
											<span class="badge badge-sm bg-secondary">
												{selectedSpearkerDepart}
											</span>
											
										</div>
										:
										''

									}
									

									<div className={"col-sm-12 "+( showDepartSelect?'d-none':'' )}>
										<div className="my-4 form-group">
											<label for="speaker">Select Speaker</label>
											
											<ErrorMessage name="speaker_id" component="div" className="alert alert-danger text-white col-12" />
											<div className="row team_select">
												{
													(selectedSpearkerDepart != '' ) ?
														(processed_team_by_depart[selectedSpearkerDepart].length > 0) ?
															processed_team_by_depart[selectedSpearkerDepart].map((speaker, index) =>
																	// <option value={speaker.id} selected="selected">{speaker.name}</option>
																	<div className="col-md-4 mt-3">
																		<div className={"team_wrapper "+((selectedSpeaker == speaker.id)?'selected' : '')} onClick={(speaker_id)=>{setSelectedSpeaker(speaker.id); setFieldValue('speaker_id',speaker.id)}}>
																			<img src={speaker.image} />
																			<p>
																				{speaker.name}<br/>
																				<small className="text-secondary">{speaker.position}</small>
																			</p>
																		</div>
																	</div>
															)
															:
															<option>No available member in this department</option>
														:
														''
												}
											</div>
										</div>
									</div>
								</div>
								<div className={"row speak_form_row form2 "+((step != 2)?'d-none':'')}>
									<div className="col-12 col-md-6">
										<Field type="text" id="speaker_id" name="speaker_id" className="d-none"/>
										<ErrorMessage name="speaker_id" component="div" className="alert alert-danger text-white col-12" />
										<div className="mb-4 form-group">
											<label for="nof_org">Name Of Organization</label>
											<Field name="name_of_organization" className="form-control mt-2" placeholder="Name of Organization" id="nof_org" />
											<ErrorMessage name="name_of_organization" component="div" className="alert alert-danger text-white col-12" />
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="mb-4 form-group">
											<label for="date_time">Date & Time</label>
											<Field name="date_and_time" type="datetime-local" className="form-control mt-2" placeholder="mm/dd/yyyy" id="date_time" />
											<ErrorMessage name="date_and_time" component="div" className="alert alert-danger text-white col-12" />
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="mb-4 form-group">
											<label for="event_location">Event Location</label>
											<Field name="event_location" className="form-control mt-2" placeholder="Event Location" id="event_location" />
											<ErrorMessage name="event_location" component="div" className="alert alert-danger text-white col-12" />
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="mb-4 form-group">
											<label for="email_address">Email Address</label>
											<Field name="email" type="email" className="form-control mt-2" placeholder="Email Address" id="email_address" />
											<ErrorMessage name="email" component="div" className="alert alert-danger text-white col-12" />
										</div>
									</div>
								
									<div className="mb-4 form-group">
										<label for="description">Description</label>
										<Field as="textarea" name="description" className="form-control mt-2" placeholder="Description" id="description" />
										<ErrorMessage name="description" component="div" className="alert alert-danger text-white col-12 mt-3" />
									</div>

								</div>
								
							    

								{(step == 1)?<button type="button" className="btn btn-info mt-3" onClick={nextForm} disabled={(selectedSpeaker)?false:true}>Continue</button>:''}
								{(step == 2)?
								<>
									<button type="button" className="btn btn-primary mt-3 mr-4 d-inline-block" onClick={prevForm}>Prev</button>
									<button type="submit" className="btn btn-sm btn-info mt-3 ml-4 d-inline-block float-end" disabled={isSubmitting}>SUBMIT</button>
								</>
								:''}

							</Form>

						)}

					</Formik>
				}
			</Modal.Body>
		</Modal>
	);
}

export default SpeakAtEventForm;