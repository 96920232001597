import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import LinkWithArrow from "../../../common/LinkWithArrow/LinkWithArrow";
import classes from './BlogBlockD2.scss';
const BlogBlockD2 = (props) => {
	const item = props.item;
	return (

		<div className={props.className ? props.className + " mt-5 mt-md-0" : 'col-md-4  mt-5 mt-md-0'}>
			<div className="blog_image_wrap_d2">
				<img src={item.image} />
			</div>

			<div className="blog_sum_wrap_d2 mt-2">
				<small className="my-3 d-block">{item.formatted_published_date}</small>
				<h2 className="h5"><Link to={"/blogs/" + item.slug}>{item.title}</Link></h2>
				<p>
					{HtmlParser(item.short_description)}
				</p>
				<LinkWithArrow link_to={"/blogs/" + item.slug} link_text="READ MORE"></LinkWithArrow>
			</div>
		</div>

	);
};

export default BlogBlockD2;