import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import classes from "./SectionTextTitleContent.scss";
import Plx from 'react-plx';


const SectionTextTitleContent = (props) => {

    const parallaxData = props.animation?? [
        // {
        //     start: 'self',
        //     startOffset: -10,
        //     duration: 500,
        //     properties: [
        //         {
        //             startValue: 0,
        //             endValue: 1,
        //             property: 'opacity',
        //         },
        //         {
        //             startValue: 300,
        //             endValue: 0,
        //             property: 'translateX',
        //         },
        //     ],
        // },
    ];

    return (
        <Plx className='st_parallax' parallaxData={parallaxData} >
            {props.title && <h1 className={"sectiontextcontent-header " + ((props.is_multi_col) ? '' : ' page_header')}>{ReactHtmlParser(props.title)}</h1>}
            {props.subtitle && <p className={props.subtitle_style ?? 'h2'}>{ReactHtmlParser(props.subtitle)}</p>}
            {props.body && <p className={"sectiontextcontent-paragraph" + ((props.is_multi_col) ? '' : '  page_summary')}>{ReactHtmlParser(props.body)}</p>}
            {props.children}
        </Plx> 
    );
}

export default SectionTextTitleContent;