import CookieConsent from "react-cookie-consent";

const CookieConsentForm = (props) => {
	return (
		<CookieConsent
			style={{
				background: "#f5f1f1d9",
				color: "rgb(94 91 91)"
			}}
			buttonStyle={{ color: "rgb(233 251 244)", fontSize: "1.2rem", background: "#18be8c", borderRadius: "5px" }}
			containerClasses="container-fluid"
			buttonText="I UNDERSTAND"
		>
			<div className="cookie_consent_wrapper">
				Please confirm you consent to our website’s use of cookies or find out more about them here. By continuing to use this site without changing your settings you consent to their use.
			</div>
		</CookieConsent>

	);
};

export default CookieConsentForm;