import './Loading.scss';

const Loading = () => {
	return(
		<div className="row justify-content-center loading_wrap">
			<img src="/images/loading.gif" />
		</div>
	);
};

export default Loading;